import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { selectTostInfo } from "../../../redux/tostSlice";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Container = styled.div.attrs(() => ({
  className: "",
}))`
  .modal-content {
    background-color: ${({ bagColor }) => bagColor};
  }
  .modal-header {
    background-color: #919191;
    color: #ffff;
    button {
      background-color: #ffff;
    }
  }
`;

const ModalBox = forwardRef(({ title = "", children, size, bagColor }, ref) => {
  const [open, setOpen] = useState(false);
  const { isModalCLose } = useSelector(selectTostInfo);

  useEffect(() => {
    isModalCLose && setOpen(false);
  }, [isModalCLose]);

  useImperativeHandle(ref, (flag = !open) => ({
    handleModalBox() {
      setOpen(flag);
    },
  }));
  const displayType = open ? "block" : "none";
  const show = open ? "show" : "";
  const modalSize = size
    ? `modal-dialog modal-${size} `
    : " modal-dialog modal-fullscreen";
  return (
    <Container bagColor={bagColor}>
      <div
        className={`modal  fade ${show}  `}
        id="exampleModal"
        style={{ display: displayType }}
        role="dialog"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className={modalSize} role="document">
          <div className={`modal-content shadow-lg`}>
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setOpen(false)}
              ></button>
            </div>

            {children}
          </div>
        </div>
      </div>
    </Container>
  );
});

ModalBox.propTypes = {};

export default ModalBox;
