import styled from "styled-components";

export const AddQuotationDetailsWrapper = styled.div`
  .input-group-text {
    font-size: 14px;
  }
  input {
    font-size: 14px;
  }
`;
