import { createSlice } from "@reduxjs/toolkit";
import { getSelector } from "./helper";
import { fetchUserDetails } from "../action/userAction";

const userSlice = createSlice({
  name: "userAccount",
  initialState: {
    userDetails: [],
    loading: false,
    isSuccess: false,
    message: "",
  },
  reducers: {
    clear: (state) => {
      state.status = false;
      state.data = [];
      state.loading = false;
    },
    updateUserDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
    updateCartItemCount: (state, { payload }) => {
      state.cartItemCount = payload;
    },
  },
  extraReducers: {
    [fetchUserDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userDetails = payload?.data;
    },
    [fetchUserDetails.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { clear, updateUserDetails, updateCartItemCount } =
  userSlice.actions;

export const selectUserAccountInfo = getSelector("userSlice", "userDetails");
export default userSlice.reducer;
