import { createSlice } from "@reduxjs/toolkit";
import { getSelector } from "./helper";
function calculatePercentage(total) {
	return (10 / 100) * total;
}
const calculation = (items) => {
	// Define the keys you want to sum
	const keysToSum = [
		"metres",
		"client_share_percentage",
		"neighbours_share_percentage",
		"client_share",
		"neighbours_share",
		"quotation_subtotal",
		"price_per_metres",
	];

	// Initialize an object to store the sums
	const sums = {};

	// Iterate over the keys to sum
	keysToSum.forEach((key) => {
		// Initialize the sum for this key to 0
		sums[key] = 0;

		// Iterate over the JSON data
		items.forEach((item) => {
			// Add the value for this key to the sum
			if (key === "client_share" || key === "neighbours_share") {
				sums[key] +=
					parseFloat(item[key]) + calculatePercentage(parseFloat(item[key]));
			} else {
				sums[key] += parseFloat(item[key]);
			}
		});
	});
	const gstAmount = calculatePercentage(sums?.quotation_subtotal);
	return {
		...sums,
		gst: parseFloat(gstAmount),
		quotation_amount: parseFloat(sums?.quotation_subtotal + gstAmount),
	};
};
const quotationSlice = createSlice({
	name: "genricQoteItem",
	initialState: {
		quoteItem: [],
		calculation: {},
	},
	reducers: {
		clear: (state) => {
			state.quoteItem = [];
		},
		updateQuoteItemSlice: (state, { payload }) => {
			state.quoteItem = payload; //[...state.quoteItem, ...payload];
			state.calculation = calculation(state.quoteItem);
		},
	},
	extraReducers: {},
});

export const { clear, updateQuoteItemSlice } = quotationSlice.actions;

export const fetchGenricQoteItem = getSelector("quotationSlice", "quoteItem");
export const fetchCalculation = getSelector("quotationSlice", "calculation");

export default quotationSlice.reducer;
