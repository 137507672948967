import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Container, LogoContainer, Wrapper } from "./styled";
import { adminLogin } from "../../action/userAction";
import { EMAIL_MOBILE_REGEX } from "../../Constant";
export const Login = () => {
  const logoImage = require(`../../images/logo.png`);

  const dispatch = useDispatch();

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (obj) => {
    dispatch(adminLogin(obj));
  };
  return (
    <Wrapper>
      <Container>
        <div class="container backimg">
          <div class="row">
            <div class="col-lg-3 col-md-2"></div>
            <div class="col-lg-5 col-md-8 login-box">
              <div class="col-lg-12 login-key">
                <i class="fa fa-key" aria-hidden="true"></i>
              </div>
              <div class="col-lg-12 mt-3 company-title">
                <LogoContainer>
                  <img
                    src={logoImage}
                    alt="..."
                    className="animated bounce"
                    style={{ width: "10rem" }}
                  />
                </LogoContainer>
              </div>
              <div class="col-lg-12 login-title">ADMIN LOGIN</div>

              <div class="col-lg-12 login-form">
                <div class="col-lg-12 login-form p-3">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div class="form-group mb-4">
                      <Controller
                        name="email"
                        control={control}
                        rules={{ required: true, pattern: EMAIL_MOBILE_REGEX }}
                        render={({ field: { onChange, name, value, ref } }) => (
                          <input
                            type="email"
                            value={value}
                            label="Email"
                            placeholder="Enter Email"
                            className={`form-control border rounded`}
                            id={name}
                            onChange={onChange}
                            ref={ref}
                          />
                        )}
                      />
                    </div>
                    <div class="form-group  mb-4">
                      <Controller
                        name="password"
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { onChange, name, value, ref } }) => (
                          <input
                            type="password"
                            value={value}
                            label="Enter Password"
                            placeholder="Enter Password"
                            className={`form-control border rounded`}
                            id={name}
                            onChange={onChange}
                            ref={ref}
                          />
                        )}
                      />
                    </div>

                    <div class="col-lg-12 loginbttm  mb-3 text-end">
                      <button
                        type="submit"
                        class="px-3 py-2 btn btn-outline-primary "
                      >
                        LOGIN
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-lg-3 col-md-2"></div>
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};
