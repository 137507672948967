import React from "react";
import { ListIcon } from "../DesignSystem/Icons/Icons";
import { superAdminMenuItems } from "../../config/nav-config";
import { useMyNavigation } from "../../hooks";
import { getPageUrl } from "../../util";

export const NavBar = ({ setOpen, open }) => {
  const path = window.location;

  const logoImage = require(`../../images/logo.png`);
  const { replace } = useMyNavigation();

  return (
    <>
      <nav className="navbar nav-header shadow-sm">
        <div className="d-flex justify-content-between navbar-brand mx-3" href>
          <div className="nav-header cp flex-grow-1">
            <img
              src={logoImage}
              alt="..."
              style={{ width: "10rem", height: "2.5rem" }}
            />
          </div>

          <div className="menu-icon" onClick={() => setOpen(!open)}>
            <ListIcon />
          </div>
        </div>
        <div className="divider "></div>
      </nav>
      <div className="d-flex flex-column nav-items gap-1 mt-4">
        {superAdminMenuItems?.map(({ icon, label, url }) => (
          <div
            className={`nav-item cp  border-bottom py-2  ${
              getPageUrl(path) === url?.split("/").pop() ? "active" : ""
            }`}
            onClick={() => replace(url)}
          >
            {/* <img src={logoImage} alt="..." /> */}
            {icon} &nbsp;<span> {label}</span>
          </div>
        ))}
      </div>
    </>
  );
};
//   {
//     superAdminMenuItems?.map(({ icon, label }) => {
//       <div className="nav-item cp shadow-sm border-bottom">
//         {icon} {label}
//         {/* <img src={logoImage} alt="..." /> */}
//       </div>;
//     });
//   }
