import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";

import { SelectField } from "../../components/DesignSystem/FormField";
import { OutlineButton } from "../../components/DesignSystem/Buttons";
import ModalBox from "../../components/DesignSystem/ModalBox";
import {
  fetchGenricQoteItem,
  updateQuoteItemSlice,
} from "../../redux/quotationSlice";
import { CURRENY } from "../../Constant";
import { AddQuotationDetailsWrapper } from "./styled";
import { useSelector } from "react-redux";

const optionsForGroup = [
  { value: "General", label: "General Item" },
  { value: "Fence", label: "Fence - Colorbond®" },
  {
    value: "Other",
    label: "Other - Demolition & Removal Of Old Fence",
  },
];
const resData = {
  info: {
    quotation_description: "",
    metres: 0,
    height: 0,
    client_share: 0,
    neighbours_share: 0,
    client_share_percentage: 0,
    neighbours_share_percentage: 0,
    quotation_subtotal: 0,
    price_per_metres: 0,
    quotation_title: "",
  },
};
const AddQuotationDetails = forwardRef(({ genricQoteItem }, ref) => {
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState("General");
  const [flag, setFlag] = useState(false);
  const [index, setIndex] = useState("");

  const genricQuoteItem = useSelector(fetchGenricQoteItem);

  const dispatch = useDispatch();
  const openModal = useRef();

  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      ...resData,
    },
  });

  useImperativeHandle(ref, () => ({
    handleAddQuotationDetails(item) {
      if (item) {
        setValue("info", item);
        setIndex(item?.index);
        if (item?.quotation_title) {
          setValue("info.quotation_title", {
            label: item?.quotation_title,
            value: item?.quotation_title,
          });
          setTitle(item?.quotation_title);
          setIsEdit(true);
        }
      } else {
        setTitle("General");

        reset(resData);
        setIsEdit(false);
      }
      openModal.current.handleModalBox();
    },
  }));

  const onSubmit = (obj) => {
    const prevItems = genricQuoteItem.filter((item, key) => key !== index);
    dispatch(
      updateQuoteItemSlice([
        ...prevItems,
        ...[
          {
            ...obj.info,
            quotation_title: obj.info?.quotation_title?.label
              ? obj.info?.quotation_title?.label
              : title,
          },
        ],
      ])
    );
    reset(resData);
    setIndex("");
    openModal.current.handleModalBox();
  };
  const onchangeDropdown = (e) => {
    reset(resData);
    setValue("info.quotation_title", e);
    setValue("info.quotation_description", e.label);
    setTitle(e.value);
  };
  const onChangeHandler = (value, type) => {
    if (type === "metres") {
      setValue(
        "info.quotation_subtotal",
        parseFloat(getValues("info.price_per_metres")) * parseFloat(value) || 0
      );

      setValue(
        "info.client_share",
        (getValues("info.neighbours_share_percentage") / 100) *
          getValues("info.quotation_subtotal") || 0
      );
      setValue(
        "info.neighbours_share",
        (getValues("info.client_share_percentage") / 100) *
          getValues("info.quotation_subtotal") || 0
      );
    }
    if (type === "quotation_subtotal") {
      setValue(
        "info.client_share",
        (getValues("info.neighbours_share_percentage") / 100) *
          parseFloat(value) || 0
      );
      setValue(
        "info.neighbours_share",
        (getValues("info.client_share_percentage") / 100) * parseFloat(value) ||
          0
      );
    }
    if (type === "price_per_metres") {
      if (title !== "General") {
        setValue(
          "info.quotation_subtotal",
          parseFloat(getValues("info.metres")) * parseFloat(value) || 0
        );
      }
      if (title === "General") {
        setValue("info.quotation_subtotal", parseFloat(value) || 0);
      }

      setValue(
        "info.client_share",
        (getValues("info.neighbours_share_percentage") / 100) *
          getValues("info.quotation_subtotal") || 0
      );
      setValue(
        "info.neighbours_share",
        (getValues("info.client_share_percentage") / 100) *
          getValues("info.quotation_subtotal") || 0
      );
    }
    if (type === "client_share_percentage" && value <= 100) {
      const neighbourShare = 100 - parseFloat(value);
      setValue(
        "info.neighbours_share_percentage",
        parseFloat(neighbourShare) || 0
      );

      setValue(
        "info.client_share",
        (parseFloat(value) / 100) * getValues("info.quotation_subtotal") || 0
      );
      setValue(
        "info.neighbours_share",
        (neighbourShare / 100) * getValues("info.quotation_subtotal") || 0
      );
    }
    if (type === "neighbours_share_percentage" && value <= 100) {
      const clientShare = 100 - parseFloat(value);
      setValue("info.client_share_percentage", 100 - parseFloat(value));

      setValue(
        "info.client_share",
        (clientShare / 100) * getValues("info.quotation_subtotal") || 0
      );
      setValue(
        "info.neighbours_share",
        (parseFloat(value) / 100) * getValues("info.quotation_subtotal") || 0
      );
    }
    setFlag(!flag);
  };
  return (
    <ModalBox
      ref={openModal}
      size="md"
      title={`${isEdit ? "Edit" : "Add"} Quotation Details`}
    >
      <AddQuotationDetailsWrapper>
        <div className="container p-2">
          <div className="p-2">
            <form className="box" onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-2">
                <div className="col-md-12 mb-3">
                  <Controller
                    name="info.quotation_title"
                    control={control}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <SelectField
                        name={name}
                        value={value}
                        optionsItems={optionsForGroup}
                        errors={errors}
                        placeholder="General Item"
                        errorMessage="Please select general item."
                        ref={ref}
                        onChange={(e) => {
                          onChange(e);
                          onchangeDropdown(e);
                        }}
                      />
                    )}
                  />
                </div>
                <div class=" col-md-12 ">
                  <Controller
                    name="info.quotation_description"
                    control={control}
                    render={({ field: { onChange, name, value, ref } }) => (
                      <div class="input-group mb-3">
                        <span
                          class="input-group-text"
                          id="inputGroup-sizing-default"
                        >
                          Description :
                        </span>
                        <input
                          type="text"
                          name={name}
                          value={value}
                          errors={errors}
                          errorMessage="Client email"
                          onChange={onChange}
                          ref={ref}
                          class="form-control"
                          aria-label="Sizing example input"
                          aria-describedby="inputGroup-sizing-default"
                        />
                      </div>
                    )}
                  />
                </div>
                {/* OPTIONAL */}
                {title !== "General" && (
                  <>
                    <div class="col-md-6">
                      <Controller
                        name="info.metres"
                        control={control}
                        rules={{
                          validate: (value) => {
                            if (
                              value === "-0" ||
                              value === "-0.0" ||
                              value === "-0.00"
                            ) {
                              return false;
                            }
                            return true;
                          },
                        }}
                        render={({ field: { onChange, name, value, ref } }) => (
                          <div class="input-group mb-3">
                            <span
                              class="input-group-text"
                              id="inputGroup-sizing-default"
                            >
                              Metres:
                            </span>
                            <input
                              type="number"
                              name={name}
                              value={value}
                              errors={errors}
                              errorMessage="Client metres"
                              onChange={(e) => {
                                onChange(e);
                                onChangeHandler(e.target.value, "metres");
                              }}
                              ref={ref}
                              class="form-control"
                              aria-label="Sizing example input"
                              aria-describedby="inputGroup-sizing-default"
                            />
                            <span
                              class="input-group-text"
                              id="inputGroup-sizing-default"
                            >
                              metres
                            </span>
                          </div>
                        )}
                      />
                    </div>
                    <div class="col-md-6">
                      <Controller
                        name="info.height"
                        control={control}
                        render={({ field: { onChange, name, value, ref } }) => (
                          <div class="input-group mb-3">
                            <span
                              class="input-group-text"
                              id="inputGroup-sizing-default"
                            >
                              Height:
                            </span>
                            <input
                              type="text"
                              name={name}
                              value={value}
                              errors={errors}
                              errorMessage="Client email"
                              onChange={onChange}
                              ref={ref}
                              class="form-control"
                              aria-label="Sizing example input"
                              aria-describedby="inputGroup-sizing-default"
                            />
                            <span
                              class="input-group-text"
                              id="inputGroup-sizing-default"
                            >
                              metres
                            </span>
                          </div>
                        )}
                      />
                    </div>
                    <div class=" col-md-12 ">
                      <Controller
                        name="info.price_per_metres"
                        control={control}
                        rules={{
                          validate: (value) => {
                            if (
                              value === "-0" ||
                              value === "-0.0" ||
                              value === "-0.00"
                            ) {
                              return false;
                            }
                            return true;
                          },
                        }}
                        render={({ field: { onChange, name, value, ref } }) => (
                          <div class="input-group mb-3">
                            <span
                              class="input-group-text"
                              id="inputGroup-sizing-default"
                            >
                              Price Per Meter (Ex Tax):
                            </span>
                            <span
                              class="input-group-text"
                              id="inputGroup-sizing-default"
                            >
                              $
                            </span>
                            <input
                              type="number"
                              name={name}
                              value={value}
                              errors={errors}
                              errorMessage="Total"
                              onChange={(e) => {
                                onChange(e);
                                onChangeHandler(
                                  e.target.value,
                                  "price_per_metres"
                                );
                              }}
                              ref={ref}
                              class="form-control"
                              aria-label="Sizing example input"
                              aria-describedby="inputGroup-sizing-default"
                            />
                          </div>
                        )}
                      />
                    </div>
                  </>
                )}

                {/* OPTIONAL */}

                <div class=" col-md-12 ">
                  <Controller
                    name="info.quotation_subtotal"
                    control={control}
                    rules={{
                      // pattern: /^\d+$/,
                      min: 0,
                      validate: (value) => {
                        if (
                          value === "-0" ||
                          value === "-0.0" ||
                          value === "-0.00"
                        ) {
                          return false;
                        }
                        return true;
                      },
                    }}
                    render={({ field: { onChange, name, value, ref } }) => (
                      <div class="input-group mb-3">
                        <span
                          class="input-group-text"
                          id="inputGroup-sizing-default"
                        >
                          Total (ex Tax):
                        </span>
                        <span
                          class="input-group-text"
                          id="inputGroup-sizing-default"
                        >
                          $
                        </span>
                        <input
                          type="number"
                          name={name}
                          value={value}
                          disabled={Boolean(title !== "General")}
                          errors={errors}
                          errorMessage="Total"
                          onChange={(e) => {
                            onChange(e);
                            onChangeHandler(
                              e.target.value,
                              "quotation_subtotal"
                            );
                          }}
                          ref={ref}
                          class="form-control"
                          aria-label="Sizing example input"
                          aria-describedby="inputGroup-sizing-default"
                        />
                      </div>
                    )}
                  />
                </div>
                <div class=" col-md-12 ">
                  <Controller
                    name="info.client_share_percentage"
                    control={control}
                    rules={{
                      pattern: /^\d+$/,
                      max: 100,
                      min: 0,
                      validate: (value) => {
                        if (
                          value === "-0" ||
                          value === "-0.0" ||
                          value === "-0.00"
                        ) {
                          return false;
                        }
                        return true;
                      },
                    }}
                    render={({ field: { onChange, name, value, ref } }) => (
                      <div class="input-group mb-3">
                        <span
                          class="input-group-text"
                          id="inputGroup-sizing-default"
                        >
                          Client Share:
                        </span>
                        <input
                          type="number"
                          name={name}
                          value={value}
                          errors={errors}
                          errorMessage="Client Share"
                          onChange={(e) => {
                            onChange(e);
                            onChangeHandler(
                              e.target.value,
                              "client_share_percentage"
                            );
                          }}
                          ref={ref}
                          class="form-control"
                          aria-label="Sizing example input"
                          aria-describedby="inputGroup-sizing-default"
                        />
                        <span
                          class="input-group-text"
                          id="inputGroup-sizing-default"
                        >
                          {/* % ={`${CURRENY}${getValues("info.client_share")}`} */}
                          % ={" "}
                          {`${CURRENY}${parseFloat(
                            getValues("info.client_share") || 0
                          ).toFixed(2)}`}
                        </span>
                      </div>
                    )}
                  />
                </div>
                <div class=" col-md-12 ">
                  <Controller
                    name="info.neighbours_share_percentage"
                    control={control}
                    rules={{
                      pattern: /^\d+$/,
                      max: 100,
                      min: 0,
                      validate: (value) => {
                        if (
                          value === "-0" ||
                          value === "-0.0" ||
                          value === "-0.00"
                        ) {
                          return false;
                        }
                        return true;
                      },
                    }}
                    render={({ field: { onChange, name, value, ref } }) => (
                      <div class="input-group mb-3">
                        <span
                          class="input-group-text"
                          id="inputGroup-sizing-default"
                        >
                          Neighbours Share:
                        </span>
                        <input
                          type="number"
                          name={name}
                          value={value}
                          errors={errors}
                          errorMessage=" Neighbours Share"
                          onChange={(e) => {
                            onChange(e);
                            onChangeHandler(
                              e.target.value,
                              "neighbours_share_percentage"
                            );
                          }}
                          ref={ref}
                          class="form-control"
                          aria-label="Sizing example input"
                          aria-describedby="inputGroup-sizing-default"
                        />
                        <span
                          class="input-group-text"
                          id="inputGroup-sizing-default"
                        >
                          % ={" "}
                          {/* {`${CURRENY}${getValues("info.neighbours_share")}`} */}
                          {`${CURRENY}${parseFloat(
                            getValues("info.neighbours_share") || 0
                          ).toFixed(2)}`}
                        </span>
                      </div>
                    )}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end mt-4">
                <OutlineButton
                  label={`${isEdit ? "Upate" : "Add"} Quotation Item`}
                  onClick={handleSubmit(onSubmit)}
                />
              </div>
            </form>
          </div>
        </div>
      </AddQuotationDetailsWrapper>
    </ModalBox>
  );
});
export default AddQuotationDetails;
