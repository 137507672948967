import React, { Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Dashboard } from "../../container/Dashboard";

import useIsAuthenticate from "../../hooks/useIsAuthenticate";
import AddQuotation from "../../container/Dashboard/AddQuotation";

export function SuperAuthRoute({ component, ...rest }) {
  const { isAdminAuthenticated } = useIsAuthenticate();
  return isAdminAuthenticated ? <Outlet /> : <Navigate to="/" />;
}
export const SuperAdminRouters = () => {
  return (
    <Suspense fallback={<div>Loading....</div>}>
      <Routes>
        <Route exact element={<SuperAuthRoute />}>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/manage-quotation" element={<AddQuotation />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
