import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../service";
import { requestApi } from "../redux/tostSlice";
import TostAction from "./toastAction";
import { dispatchErrorToastAction } from "./commonFunction";

export const adminLogin = createAsyncThunk(
  "userSlice/adminLogin",
  async (object, { dispatch, rejectWithValue }) => {
    try {
      dispatch(requestApi());
      const param = { method: "post", endpoint: "userInfo", object: object };

      const retunThunk = request(param);
      retunThunk
        .then((res) => {
          if (res?.token) {
            localStorage.setItem("access_token", res?.token);
            TostAction(dispatch, res, false, false, false, false);
            dispatch(fetchUserDetails());
          }
        })
        .catch((error) => dispatchErrorToastAction(error, dispatch));
      return retunThunk;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchUserDetails = createAsyncThunk(
  "user/fetchUserDetails",
  async (queryparam, { dispatch, rejectWithValue }) => {
    try {
      //dispatch(requestApi());
      const param = {
        method: "get",
        endpoint: "adminUser",
        id: queryparam,
      };

      const retunThunk = request(param);
      retunThunk
        .then((res) => {
          //TostAction(dispatch, res, false, false, false, false);
        })
        .catch((error) => dispatchErrorToastAction(error, dispatch));
      return retunThunk;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
