import { combineReducers } from "redux";
import userSlice from "./userSlice";
import tostSlice from "./tostSlice";

import quotationSlice from "./quotationSlice";
import quotationMngSlice from "./quotationMngSlice";

const rootReducer = combineReducers({
  userSlice,
  tostSlice,
  quotationMngSlice,
  quotationSlice,
});

export default rootReducer;
