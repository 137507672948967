import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../service";
import TostAction from "./toastAction";
import { requestApi } from "../redux/tostSlice";
import { dispatchErrorToastAction } from "./commonFunction";

export const addQuatation = createAsyncThunk(
	"quotationSlice/addQuatation",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "quotation",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, true, true, false, true);
					dispatch(fetchQuotationsDetails());
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const fetchQuotationsDetails = createAsyncThunk(
	"quotationSlice/fetchQuotationsDetails",
	async (queryparam, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "get",
				endpoint: "quotation",
				id: queryparam,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteQuotation = createAsyncThunk(
	"quotationSlice/deleteQuotation",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "deleteQuotation",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, true, true, false, true);
					dispatch(fetchQuotationsDetails());
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const sendMail = createAsyncThunk(
	"quotationSlice/sendMail",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "sendMail",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, true, true, false, true);
					dispatch(fetchQuotationsDetails());
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
export const updateQuotationStatus = createAsyncThunk(
	"quotationSlice/updateQuotationStatus",
	async (object, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "post",
				endpoint: "updateQuotationStatus",
				object: object,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, true, true, false, true);
					dispatch(fetchQuotationsDetails());
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getQuotationDetails = createAsyncThunk(
	"quotationSlice/getQuotationDetails",
	async (queryparam, { dispatch, rejectWithValue }) => {
		try {
			dispatch(requestApi());
			const param = {
				method: "getById",
				endpoint: "quotation",
				id: queryparam,
			};

			const retunThunk = request(param);
			retunThunk
				.then((res) => {
					TostAction(dispatch, res, 0, 0, 0);
				})
				.catch((error) => dispatchErrorToastAction(error, dispatch));
			return retunThunk;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);
