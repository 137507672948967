import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo } from "react";
import {
  LockIcon,
  RemoveIcon,
  UnLockIcon,
  EditIcon,
  ManagementIcon,
  PdfIcon,
  EmailIcon,
} from "../Icons/Icons";

const AGTabla = ({
  rowData,
  columnDefs,
  defaultColDef,
  changeStatus,
  handlePDF,
  handleMail,
  handleDelete,
  handleEdit,
}) => {
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "25rem" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const sideBar = useMemo(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);
  const columnDefs1 = [
    {
      headerName: "Actions",
      field: "id",
      //cellRenderer: restaurantActions,
      cellRenderer: (params) => {
        return (
          <>
            {/* <button
              className={`btn ${
                params.data.status == "P" ? "" : ""
              } btn-sm mb-2`}
              onClick={() => changeStatus(params)}>
              {params.data.status == "P" ? <LockIcon /> : <UnLockIcon />}
            </button> */}
            <button
              className="btn  btn-sm mb-2"
              onClick={() => handleDelete(params.data.id)}
            >
              <RemoveIcon size={25} />
            </button>

            <button
              className="btn  btn-sm mb-2"
              onClick={() => handleEdit(params.data)}
            >
              <EditIcon />
            </button>
            <button
              className="btn  btn-sm mb-2"
              onClick={() => handlePDF(params.data.id)}
            >
              <PdfIcon />
            </button>
            <button
              className="btn  btn-sm mb-2"
              onClick={() => handleMail(params.data.id)}
            >
              <EmailIcon size={24} />
            </button>
          </>
        );
      },
    },
  ];
  const columnDefs2 = columnDefs1.concat(columnDefs);

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className={"ag-theme-quartz"}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs2}
          defaultColDef={defaultColDef}
          sideBar={sideBar}
          // pagination={true}
          // paginationPageSize={20}
        />
      </div>
    </div>
  );
};
export default AGTabla;
