import React, { forwardRef } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import styled from "styled-components";
import { isObjectEmpty } from "../../../util";

const animatedComponents = makeAnimated();
export const colourOptions = [
  { value: "ocean", label: "Ocean", color: "#00B8D9", isFixed: true },
  { value: "blue", label: "Blue", color: "#0052CC", isDisabled: true },
];

const Container = styled.div`
  .css-48brv-control {
    border-color: #dee2e6;
  }
`;

const getErrorField = (errors, name) => {
  const fieldName = name.split(".");
  return !isObjectEmpty(errors)
    ? fieldName?.length === 2
      ? errors[fieldName[0]][fieldName[1]]
      : errors[name]
    : "";
};
export const SelectField = forwardRef(
  (
    {
      name,
      optionsItems,
      errors,
      values,
      isMulti = false,
      isDisabled = false,
      placeholder,
      errorMessage,
      className,
      type,
      ...rest
    },
    ref
  ) => {
    return (
      <Container>
        <Select
          components={animatedComponents}
          //Select={opt}
          value={values}
          //defaultValue={optionsItems[0]}
          options={optionsItems}
          isMulti={isMulti}
          placeholder={placeholder}
          isDisabled={isDisabled}
          className={className}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              // className: className,
              borderColor: errors && getErrorField(errors, name) ? "red" : "",
              boxShadow: "none",
            }),
          }}
          id={name}
          ref={ref}
          {...rest}
        />
      </Container>
    );
  }
);
