import React from "react";

import { useSelector } from "react-redux";
import {
  clear,
  fetchCalculation,
  fetchGenricQoteItem,
  updateQuoteItemSlice,
} from "../../redux/quotationSlice";
import { CURRENY } from "../../Constant";
import {
  EditIcon,
  RemoveIcon,
} from "../../components/DesignSystem/Icons/Icons";
import { useDispatch } from "react-redux";

export const QuotationItems = ({ addQuotationDetails }) => {
  const genricQoteItem = useSelector(fetchGenricQoteItem);
  const getCalculation = useSelector(fetchCalculation);
  const dispatch = useDispatch();

  const handleDelete = (index) => {
    // const filteredItem = genricQoteItem.splice(index, 1);
    const newArray = genricQoteItem.filter((item, key) => key !== index);
    //dispatch(clear());

    dispatch(updateQuoteItemSlice(newArray));
  };
  const handleEdit = (item) => {
    addQuotationDetails(item);
  };
  return (
    <>
      <>
        <table class="table table-hover table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Description </th>
              <th scope="col">Total (ex Tax)</th>
              <th scope="col" className="text-end">
                Action
              </th>
            </tr>
          </thead>

          {Boolean(genricQoteItem?.length) && (
            <tbody style={{ fontSize: 14 }}>
              {genricQoteItem?.map((item, key) => (
                <tr>
                  <th scope="row">{key + 1}</th>
                  <td className="col-8">
                    {item?.quotation_description}
                    {item?.quotation_title !== "General" && (
                      <div
                        style={{
                          fontSize: 10,
                          color: "gray",
                        }}
                      >
                        {` Dimensions = ${item?.metres}m (length) x ${item?.height}m (height), Price =
                      ${item?.metres}m x  ${CURRENY}${item?.price_per_metres} (price per meter)`}
                      </div>
                    )}
                  </td>
                  <td className="col-2">
                    <div style={{ fontSize: 14, color: "black" }}>
                      {CURRENY}
                      {item?.quotation_subtotal}
                    </div>
                    <div
                      className="row"
                      style={{
                        fontSize: 12,
                        color: "gray",
                        fontWeight: "bold",
                      }}
                    >
                      <div className="col-md-6">
                        {" "}
                        {`C= ${item?.client_share_percentage}%`}
                      </div>
                      <div className="col-md-6">
                        {`N= ${item?.neighbours_share_percentage}%`}
                      </div>
                    </div>
                  </td>
                  <td className="col-2 text-end">
                    <div
                      className="btn  btn-sm mb-2"
                      onClick={() => handleEdit({ ...item, index: key })}
                    >
                      <EditIcon size={25} />
                    </div>
                    <div
                      className="btn  btn-sm mb-2"
                      onClick={() => handleDelete(key)}
                    >
                      <RemoveIcon size={25} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
        {!Boolean(genricQoteItem?.length) && (
          <div className="text-center"> No Records Found</div>
        )}
        {Boolean(genricQoteItem?.length) && (
          <div
            className="row"
            style={{
              fontSize: 15,
            }}
          >
            <div className="col-4"></div>
            <div className="col-8">
              <ul class="list-group">
                <li class="list-group-item">
                  <div className="d-flex justify-content-between ">
                    <div>Sub Total (Ex Tax):</div>
                    <div>
                      {CURRENY}
                      {(getCalculation?.quotation_subtotal).toFixed(2)}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="d-flex justify-content-between ">
                    <div>GST (10%): </div>
                    <div>
                      {CURRENY}
                      {(getCalculation?.gst).toFixed(2)}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="d-flex justify-content-between ">
                    <div>Total (Inc Tax):</div>
                    <div>
                      {CURRENY}
                      {(getCalculation?.quotation_amount).toFixed(2)}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="d-flex justify-content-between ">
                    <div>Clients Share (Inc Tax): </div>
                    <div>
                      {CURRENY}
                      {(getCalculation?.client_share).toFixed(2)}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="d-flex justify-content-between ">
                    <div>Neighbours Share (Inc Tax):</div>
                    <div>
                      {CURRENY}
                      {(getCalculation?.neighbours_share).toFixed(2)}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </>
    </>
  );
};
