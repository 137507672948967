import React, { useEffect } from "react";
import AGTable from "../../components/DesignSystem/AGTable";
import { columnDefs, defaultColDef } from "./Header";
import { OutlineButton } from "../../components/DesignSystem/Buttons";

import { Typography } from "../../components/DesignSystem/Typography";
import { useMyNavigation } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { getQuotationInfo } from "../../redux/quotationMngSlice";

import { clear } from "../../redux/quotationSlice";
import {
  deleteQuotation,
  fetchQuotationsDetails,
  sendMail,
} from "../../action";

export const Dashboard = () => {
  const { push } = useMyNavigation();
  const dispatch = useDispatch();
  const quotationDetails = useSelector(getQuotationInfo);

  const handleDelete = (id) => {
    dispatch(deleteQuotation({ id: id }));
  };

  const handlePDF = (id) => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/api/quotationPDF/` + id,
      "_blank"
    );
  };
  const handleMail = (id) => {
    dispatch(sendMail({ id: id }));
  };
  const addQuatation = ({ id }) => {
    dispatch(clear());
    if (id) push("manage-quotation", { id });
    else push("manage-quotation");
  };
  const handleEdit = ({ id }) => {
    dispatch(clear());
    push("manage-quotation", { id });
  };

  useEffect(() => {
    dispatch(fetchQuotationsDetails());
  }, [dispatch]);
  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        {/* onClick={addBanner} */}
        <Typography align="text-start">Quotation Listing</Typography>
        <OutlineButton label="Add Quotation" onClick={addQuatation} />
      </div>
      <AGTable
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        //changeStatus={changeStatus}
        handlePDF={handlePDF}
        handleMail={handleMail}
        rowData={quotationDetails || []}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
      />
    </>
  );
};
