import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { createLogger } from "redux-logger";

import rootReducer from "./redux";

const loggerMiddleware = createLogger();
export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
  // middleware: [thunk, loggerMiddleware],
});
