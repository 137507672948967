export const EMAIL_MOBILE_REGEX =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const CURRENY = "$";

export const ClientOptionsForMobileType = [
	{ value: "Client Phone", label: "Client Phone" },
	{ value: "Client Phone (H)", label: "Client Phone (H)" },
	{
		value: "Client Phone (W)",
		label: "Client Phone (W)",
	},
	{
		value: "Client Phone (M)",
		label: "Client Phone (M)",
	},
	{
		value: "Client Phone (AH)",
		label: "Client Phone (AH)",
	},
];
export const NeighbourOptionsForMobileType = [
	{ value: "Neighbour Phone", label: "Neighbour Phone" },
	{ value: "Neighbour Phone (H)", label: "Neighbour Phone (H)" },
	{
		value: "Neighbour Phone (W)",
		label: "Neighbour Phone (W)",
	},
	{
		value: "Neighbour Phone (M)",
		label: "Neighbour Phone (M)",
	},
	{
		value: "Neighbour Phone (AH)",
		label: "Neighbour Phone (AH)",
	},
];
