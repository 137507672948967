import React from "react";
import { logout } from "../../util";
import { ListIcon, LogoutIcon } from "../DesignSystem/Icons/Icons";
import { useMyNavigation } from "../../hooks/useMyNavigation";

export const Header = ({ setOpen, open }) => {
  const { go } = useMyNavigation();
  const logoutPage = () => {
    go("/");
    logout();
  };
  return (
    <nav className="navbar navbar-expand header h-20 navbar-dark  px-3 py-0">
      <div className="">
        <a
          href
          className={`sidebar-toggler flex-shrink-0 ${!open ? "" : ""}`}
          onClick={() => setOpen(!open)}
        >
          <i className="fa fa-bars">
            <ListIcon />
          </i>
        </a>
      </div>
      <div className="navbar-nav align-items-center ms-auto">
        <a href className="nav-link cp" onClick={() => go("/info")}>
          {/* <InfoIcon size={25} /> */}
        </a>{" "}
        <a href className="nav-link cp">
          {/* <CartIcon size={25} /> */}
        </a>
        <div className="nav-item dropdown">
          <a
            href
            className="nav-link dropdown-toggle cp"
            data-bs-toggle="dropdown"
            onClick={logoutPage}
          >
            <LogoutIcon size={30} />
          </a>
        </div>
      </div>
    </nav>
  );
};
