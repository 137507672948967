import { createSlice } from "@reduxjs/toolkit";
import { getSelector } from "./helper";

import {
	fetchQuotationsDetails,
	getQuotationDetails,
} from "../action/quotationMngAction";

const quotationMngSlice = createSlice({
	name: "quotationMng",
	initialState: {
		quotationDetails: [],
		getQuotationDetails: [],
		loading: false,
		isSuccess: false,
		message: "",
	},
	reducers: {
		clear: (state) => {
			state.status = false;
			state.data = [];
			state.loading = false;
		},
		updateUserDetails: (state, { payload }) => {
			state.userDetails = payload;
		},
		updateCartItemCount: (state, { payload }) => {
			state.cartItemCount = payload;
		},
	},
	extraReducers: {
		[fetchQuotationsDetails.pending]: (state, action) => {
			state.loading = true;
		},
		[fetchQuotationsDetails.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.quotationDetails = payload?.items;
		},
		[fetchQuotationsDetails.rejected]: (state, action) => {
			state.loading = false;
		},
		[getQuotationDetails.pending]: (state, action) => {
			state.loading = true;
		},
		[getQuotationDetails.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.getQuotationDetails = payload?.items;
		},
		[getQuotationDetails.rejected]: (state, action) => {
			state.loading = false;
		},
	},
});

export const { clear, updateUserDetails, updateCartItemCount } =
	quotationMngSlice.actions;

export const getQuotationInfo = getSelector(
	"quotationMngSlice",
	"quotationDetails"
);
export const getQuotationDet = getSelector(
	"quotationMngSlice",
	"getQuotationDetails"
);

export default quotationMngSlice.reducer;
