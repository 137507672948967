export const LockIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm0-2h12V10H6zm6-3q.825 0 1.413-.587T14 15q0-.825-.587-1.412T12 13q-.825 0-1.412.588T10 15q0 .825.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6zM6 20V10z"
		/>
	</svg>
);

export const UnLockIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 36 36"
	>
		<path
			fill={color}
			d="M12 25.14V28h2v-2.77a2.42 2.42 0 1 0-2-.09"
			class="clr-i-outline clr-i-outline-path-1"
		/>
		<path
			fill={color}
			d="M26 2a8.2 8.2 0 0 0-8 8.36V15H2v17a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V15h-4v-4.64A6.2 6.2 0 0 1 26 4a6.2 6.2 0 0 1 6 6.36v6.83a1 1 0 0 0 2 0v-6.83A8.2 8.2 0 0 0 26 2m-4 15v15H4V17Z"
			class="clr-i-outline clr-i-outline-path-2"
		/>
		<path fill="none" d="M0 0h36v36H0z" />
	</svg>
);
export const RemoveIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		viewBox="0 0 50 50"
	>
		<path
			fill={color}
			d="M20 18h2v16h-2zm4 0h2v16h-2zm4 0h2v16h-2zm-16-6h26v2H12zm18 0h-2v-1c0-.6-.4-1-1-1h-4c-.6 0-1 .4-1 1v1h-2v-1c0-1.7 1.3-3 3-3h4c1.7 0 3 1.3 3 3z"
		/>
		<path
			fill={color}
			d="M31 40H19c-1.6 0-3-1.3-3.2-2.9l-1.8-24l2-.2l1.8 24c0 .6.6 1.1 1.2 1.1h12c.6 0 1.1-.5 1.2-1.1l1.8-24l2 .2l-1.8 24C34 38.7 32.6 40 31 40"
		/>
	</svg>
);
export const CopyIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		hight={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={size}
			d="M18 2H9c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h9c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m0 14H9V4h9zM3 15v-2h2v2zm0-5.5h2v2H3zM10 20h2v2h-2zm-7-1.5v-2h2v2zM5 22c-1.1 0-2-.9-2-2h2zm3.5 0h-2v-2h2zm5 0v-2h2c0 1.1-.9 2-2 2M5 6v2H3c0-1.1.9-2 2-2"
		/>
	</svg>
);
export const PlusIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		viewBox="0 0 50 50"
	>
		<path
			fill={color}
			d="M25 42c-9.4 0-17-7.6-17-17S15.6 8 25 8s17 7.6 17 17s-7.6 17-17 17m0-32c-8.3 0-15 6.7-15 15s6.7 15 15 15s15-6.7 15-15s-6.7-15-15-15"
		/>
		<path fill={color} d="M16 24h18v2H16z" />
		<path fill={color} d="M24 16h2v18h-2z" />
	</svg>
);
export const EditIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		viewBox="0 0 24 24"
	>
		<g
			fill="none"
			stroke={color}
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.45"
		>
			<path d="M7 7H6a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2-2v-1" />
			<path d="M20.385 6.585a2.1 2.1 0 0 0-2.97-2.97L9 12v3h3zM16 5l3 3" />
		</g>
	</svg>
);
export const EditPencileIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		viewBox="0 0 24 24"
	>
		<path
			fill="none"
			stroke={color}
			d="M4.5 17.207V19a.5.5 0 0 0 .5.5h1.793a.5.5 0 0 0 .353-.146l8.5-8.5l-2.5-2.5l-8.5 8.5a.5.5 0 0 0-.146.353ZM15.09 6.41l2.5 2.5l1.203-1.203a1 1 0 0 0 0-1.414l-1.086-1.086a1 1 0 0 0-1.414 0z"
		/>
	</svg>
);
export const RightArrowIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		viewBox="0 0 256 256"
	>
		<path
			fill={color}
			d="M128 28a100 100 0 1 0 100 100A100.11 100.11 0 0 0 128 28m0 192a92 92 0 1 1 92-92a92.1 92.1 0 0 1-92 92m42.83-94.83a4 4 0 0 1 0 5.66l-32 32a4 4 0 0 1-5.66-5.66L158.34 132H88a4 4 0 0 1 0-8h70.34l-25.17-25.17a4 4 0 0 1 5.66-5.66Z"
		/>
	</svg>
);
export const SuccessIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		viewBox="0 0 1024 1024"
	>
		<path
			fill={color}
			d="M512 64a448 448 0 1 1 0 896a448 448 0 0 1 0-896m-55.808 536.384l-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.27 38.27 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336z"
		/>
	</svg>
);
export const CloseIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		viewBox="0 0 24 24"
	>
		<path
			fill="none"
			stroke={color}
			stroke-linecap="round"
			d="M12 21a9 9 0 1 1 0-18a9 9 0 0 1 0 18zM9 9l6 6m0-6l-6 6"
		/>
	</svg>
);
export const LogoutIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="m17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4z"
		/>
	</svg>
);

export const RestaurantIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 512 512"
	>
		<path
			fill={color}
			d="M342.7 223.94h14.87a79.48 79.48 0 0 0 56.58-23.44L496 118l-22.22-22.4l-83.58 83.58l-17.37-17.37l83.58-83.59l-23-22.31l-83.27 83.26l-17.32-17.37l83.58-83.59L394 16l-82.5 81.85a79.49 79.49 0 0 0-23.44 56.59v14.86l-43.13 43.13L48 16C3.72 70.87 29.87 171.71 79.72 221.57l85.5 85.5c26.55 26.55 31.82 28.92 61.94 16.8c6.49-2.61 8.85-2.32 14.9 3.72l13 12.13c2.93 3 3 3.88 3 9.62v5.54c0 21.08 13.48 33.2 22.36 42.24L384 496l72-72l-156.43-156.93Z"
		/>
		<path
			fill={color}
			d="M227.37 354.59c-29.82 6.11-48.11 11.74-83.08-23.23c-.56-.56-1.14-1.1-1.7-1.66l-19.5-19.5L16 416l80 80l144-144Z"
		/>
	</svg>
);

export const ManagementIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 32 32"
	>
		<path
			fill={color}
			d="M15 20H9a3 3 0 0 0-3 3v2h2v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2h2v-2a3 3 0 0 0-3-3m-3-1a4 4 0 1 0-4-4a4 4 0 0 0 4 4m0-6a2 2 0 1 1-2 2a2 2 0 0 1 2-2"
		/>
		<path
			fill={color}
			d="M28 19v9H4V8h12V6H4a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h24a2 2 0 0 0 2-2v-9Z"
		/>
		<path
			fill={color}
			d="M20 19h6v2h-6zm2 4h4v2h-4zm10-13V8h-2.101a4.968 4.968 0 0 0-.732-1.753l1.49-1.49l-1.414-1.414l-1.49 1.49A4.968 4.968 0 0 0 26 4.101V2h-2v2.101a4.968 4.968 0 0 0-1.753.732l-1.49-1.49l-1.414 1.414l1.49 1.49A4.968 4.968 0 0 0 20.101 8H18v2h2.101a4.968 4.968 0 0 0 .732 1.753l-1.49 1.49l1.414 1.414l1.49-1.49a4.968 4.968 0 0 0 1.753.732V16h2v-2.101a4.968 4.968 0 0 0 1.753-.732l1.49 1.49l1.414-1.414l-1.49-1.49A4.968 4.968 0 0 0 29.899 10zm-7 2a3 3 0 1 1 3-3a3.003 3.003 0 0 1-3 3"
		/>
	</svg>
);

export const OrderListIcon = ({ size = 30, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M7 14v-2h10v2zm0 4v-2h7v2zm-4 4V4h3V2h2v2h8V2h2v2h3v18zm2-2h14V10H5zM5 8h14V6H5zm0 0V6z"
		/>
	</svg>
);

export const ListIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		hight={`${size}px`}
		fill={color}
		class="bi bi-list"
		viewBox="0 0 16 16"
	>
		<path
			fill-rule="evenodd"
			d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
		/>
	</svg>
);

export const BasicSetup = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 48 48"
	>
		<defs>
			<mask id="ipSSettingWeb0">
				<g fill="none">
					<path
						stroke="#fff"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="4"
						d="M24 40H7a3 3 0 0 1-3-3V11a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3v12.059"
					/>
					<path
						fill="#fff"
						stroke="#fff"
						stroke-width="4"
						d="M4 11a3 3 0 0 1 3-3h34a3 3 0 0 1 3 3v9H4z"
					/>
					<circle r="2" fill="#000" transform="matrix(0 -1 -1 0 10 14)" />
					<circle r="2" fill="#000" transform="matrix(0 -1 -1 0 16 14)" />
					<circle cx="37" cy="34" r="3" stroke="#fff" stroke-width="4" />
					<path
						stroke="#fff"
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="4"
						d="M37 41v-4m0-6v-4m-6.062 10.5l3.464-2m5.196-3l3.464-2m-12.124 0l3.464 2m5.196 3l3.464 2"
					/>
				</g>
			</mask>
		</defs>
		<path fill={color} d="M0 0h48v48H0z" mask="url(#ipSSettingWeb0)" />
	</svg>
);
export const BasicInfo = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 16 16"
	>
		<g fill={color}>
			<path d="M5 8a2 2 0 1 0 0-4a2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5" />
			<path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96q.04-.245.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 1 1 12z" />
		</g>
	</svg>
);

export const WebsiteIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M16.36 14c.08-.66.14-1.32.14-2c0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2m-5.15 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 0 1-4.33 3.56M14.34 14H9.66c-.1-.66-.16-1.32-.16-2c0-.68.06-1.35.16-2h4.68c.09.65.16 1.32.16 2c0 .68-.07 1.34-.16 2M12 19.96c-.83-1.2-1.5-2.53-1.91-3.96h3.82c-.41 1.43-1.08 2.76-1.91 3.96M8 8H5.08A7.923 7.923 0 0 1 9.4 4.44C8.8 5.55 8.35 6.75 8 8m-2.92 8H8c.35 1.25.8 2.45 1.4 3.56A8.008 8.008 0 0 1 5.08 16m-.82-2C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2c0 .68.06 1.34.14 2M12 4.03c.83 1.2 1.5 2.54 1.91 3.97h-3.82c.41-1.43 1.08-2.77 1.91-3.97M18.92 8h-2.95a15.65 15.65 0 0 0-1.38-3.56c1.84.63 3.37 1.9 4.33 3.56M12 2C6.47 2 2 6.5 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2"
		/>
	</svg>
);
export const ViewIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<g fill="none" stroke={color}>
			<circle cx="12" cy="12" r="3.5" />
			<path d="M20.188 10.934c.388.472.582.707.582 1.066c0 .359-.194.594-.582 1.066C18.768 14.79 15.636 18 12 18c-3.636 0-6.768-3.21-8.188-4.934c-.388-.472-.582-.707-.582-1.066c0-.359.194-.594.582-1.066C5.232 9.21 8.364 6 12 6c3.636 0 6.768 3.21 8.188 4.934Z" />
		</g>
	</svg>
);
export const ArrowBackUpIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="m4 10l-.354.354L3.293 10l.353-.354zm16.5 8a.5.5 0 0 1-1 0zM8.646 15.354l-5-5l.708-.708l5 5zm-5-5.708l5-5l.708.708l-5 5zM4 9.5h10v1H4zM20.5 16v2h-1v-2zM14 9.5a6.5 6.5 0 0 1 6.5 6.5h-1a5.5 5.5 0 0 0-5.5-5.5z"
		/>
	</svg>
);

export const AccountConfIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M22 3H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 16H2V5h20zM21 6h-7v5h7zm-1 2l-2.5 1.75L15 8V7l2.5 1.75L20 7zM9 12a3 3 0 0 0 3-3a3 3 0 0 0-3-3a3 3 0 0 0-3 3a3 3 0 0 0 3 3m0-4a1 1 0 0 1 1 1a1 1 0 0 1-1 1a1 1 0 0 1-1-1a1 1 0 0 1 1-1m6 8.59c0-2.5-3.97-3.59-6-3.59s-6 1.09-6 3.59V18h12zM5.5 16c.72-.5 2.2-1 3.5-1c1.3 0 2.77.5 3.5 1z"
		/>
	</svg>
);
export const LocatonIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M12 11.5A2.5 2.5 0 0 1 9.5 9A2.5 2.5 0 0 1 12 6.5A2.5 2.5 0 0 1 14.5 9a2.5 2.5 0 0 1-2.5 2.5M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7"
		/>
	</svg>
);
export const NameIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 256 256"
	>
		<path
			fill={color}
			d="M83.19 174.4a8 8 0 0 0 11.21-1.6a52 52 0 0 1 83.2 0a8 8 0 1 0 12.8-9.6a67.88 67.88 0 0 0-27.4-21.69a40 40 0 1 0-53.94 0A67.88 67.88 0 0 0 81.6 163.2a8 8 0 0 0 1.59 11.2M112 112a24 24 0 1 1 24 24a24 24 0 0 1-24-24m96-88H64a16 16 0 0 0-16 16v24H32a8 8 0 0 0 0 16h16v40H32a8 8 0 0 0 0 16h16v40H32a8 8 0 0 0 0 16h16v24a16 16 0 0 0 16 16h144a16 16 0 0 0 16-16V40a16 16 0 0 0-16-16m0 192H64V40h144Z"
		/>
	</svg>
);
export const CuisineIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 512 512"
	>
		<path
			fill={color}
			d="M464 344.063c0-109.308-84.755-199.193-192-207.39V80h-32v56.673c-107.245 8.2-192 98.082-192 207.39v33.107h416Zm-32 1.107H80v-1.107c0-97.046 78.953-176 176-176s176 78.953 176 176ZM16 416h480v32H16z"
		/>
	</svg>
);
export const ServiceIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 64 64"
	>
		<path
			fill={color}
			d="M56.824 35.562V23.041c0-1.777 1.454-3.222 3.241-3.222a3.222 3.222 0 0 1 3.221 3.222v17.145a9.925 9.925 0 0 1-2.383 6.449L50.212 57.989c-1.061.999-1.321 1.883-1.321 3.223v2.067H37.254l-.013-4.918c0-1.873-.153-4.376 2.191-6.832l13.566-13.622a2.574 2.574 0 0 1 3.751 3.522l-6.543 7.009c-.424.497-1.142 1.434-1.498 2.582c-.192.596-.247 1.177-.258 1.844h2.106c.008-.475.044-.857.157-1.211c.206-.662.616-1.258.925-1.64l6.642-7.29c.835-.842 1.293-1.932 1.293-3.078c0-1.721-1.035-3.304-2.747-4.084zm-49.025 0V23.041c0-1.777-1.454-3.222-3.241-3.222a3.222 3.222 0 0 0-3.221 3.222v17.145c0 2.371.853 4.661 2.384 6.449l10.692 11.354c1.06.999 1.321 1.883 1.321 3.223v2.067h11.637l.013-4.918c0-1.873.153-4.376-2.191-6.832L11.627 37.907a2.574 2.574 0 0 0-3.751 3.522l6.544 7.009c.424.497 1.142 1.434 1.498 2.582c.193.596.247 1.177.258 1.844H14.07c-.008-.475-.043-.857-.158-1.211c-.205-.662-.616-1.258-.925-1.64l-6.642-7.289c-.835-.842-1.293-1.933-1.293-3.078c0-1.722 1.035-3.305 2.747-4.085zM33.536 6.123a2.762 2.762 0 0 0 0-5.526a2.763 2.763 0 0 0 0 5.526m-14.987.015a2.79 2.79 0 0 1-2.802-2.781a2.79 2.79 0 0 1 2.802-2.78c1.548 0 2.806 1.244 2.806 2.78c0 1.539-1.258 2.781-2.806 2.781m28.712 10.664a1.938 1.938 0 1 0-.002-3.876a1.938 1.938 0 0 0 .002 3.876"
		/>
		<path
			fill={color}
			d="M50.067 17.758H44.84c-.024 0-1.749-.157-1.749-.157l-2.189-7.63c-.881-3.072-3.457-2.978-3.457-2.978h-7.086s-2.574-.094-3.455 2.978l-1.081 3.757l-1.078-3.757c-.886-3.072-3.455-2.978-3.455-2.978h-5.483s-2.576-.094-3.457 2.978l-2.207 7.689c-.21.72.184 1.266.814 1.448c.628.179 1.271-.138 1.432-.711l2.15-7.495l1.047-.002l-3.138 12.977h2.773v9.218c0 .759.612 1.377 1.373 1.377c.759 0 1.38-.618 1.38-1.377v-9.218h1.163v9.218c0 .759.613 1.377 1.372 1.377a1.38 1.38 0 0 0 1.382-1.377v-9.218h2.725L21.511 10.9l1.043.002l2.126 7.416c.035.127.092.243.166.343c.208.315.571.489.975.476c.404.013.765-.16.973-.47a1.01 1.01 0 0 0 .181-.383l2.115-7.383l.964-.002v21.928c0 .896.733 1.62 1.627 1.62c.895 0 1.62-.724 1.62-1.62V20.351h1.198v12.476c0 .896.726 1.62 1.618 1.62c.897 0 1.632-.724 1.632-1.62V10.899l1.048.002l2.058 7.495c.112.369.42.613.789.707c.016.004.024.011.037.015c.328.074 1.84.503 2.95.82v13.6a1.141 1.141 0 0 0 2.28 0v-6.794h.892v6.794c0 .628.509 1.137 1.137 1.137s1.144-.509 1.144-1.137V20.834h.875v4.304c0 .473.383.853.848.853a.842.842 0 0 0 .838-.853v-4.896c.002-1.371-1.205-2.484-2.576-2.484z"
		/>
	</svg>
);
export const MoneyIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M12 12.5a3.5 3.5 0 1 0 0 7a3.5 3.5 0 0 0 0-7M10.5 16a1.5 1.5 0 1 1 3 0a1.5 1.5 0 0 1-3 0"
		/>
		<path
			fill={color}
			d="M17.526 5.116L14.347.659L2.658 9.997L2.01 9.99V10H1.5v12h21V10h-.962l-1.914-5.599zM19.425 10H9.397l7.469-2.546l1.522-.487zM15.55 5.79L7.84 8.418l6.106-4.878zM3.5 18.169v-4.34A3.008 3.008 0 0 0 5.33 12h13.34a3.009 3.009 0 0 0 1.83 1.83v4.34A3.009 3.009 0 0 0 18.67 20H5.332A3.01 3.01 0 0 0 3.5 18.169"
		/>
	</svg>
);

export const PaymentIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2m0 14H4v-6h16zm0-10H4V6h16z"
		/>
	</svg>
);
export const ShareIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81c1.66 0 3-1.34 3-3s-1.34-3-3-3s-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65c0 1.61 1.31 2.92 2.92 2.92c1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92"
		/>
	</svg>
);
export const FacebookIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12c0 4.84 3.44 8.87 8 9.8V15H8v-3h2V9.5C10 7.57 11.57 6 13.5 6H16v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v6.95c5.05-.5 9-4.76 9-9.95"
		/>
	</svg>
);
export const ShareAppIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M17 1.01L7 1c-1.1 0-1.99.9-1.99 2v18c0 1.1.89 2 1.99 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99M17 19H7V5h10zm-4.2-5.78v1.75l3.2-2.99L12.8 9v1.7c-3.11.43-4.35 2.56-4.8 4.7c1.11-1.5 2.58-2.18 4.8-2.18"
		/>
	</svg>
);
export const EmailIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7L4 8v10h16V8zm0-2l8-5H4zM4 8V6v12z"
		/>
	</svg>
);
export const PickupIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M16 6h-5.5v4H1v5h2a3 3 0 0 0 3 3a3 3 0 0 0 3-3h6a3 3 0 0 0 3 3a3 3 0 0 0 3-3h2v-3c0-1.11-.89-2-2-2h-2zm-4 1.5h3.5l1.96 2.5H12zm-6 6A1.5 1.5 0 0 1 7.5 15A1.5 1.5 0 0 1 6 16.5A1.5 1.5 0 0 1 4.5 15A1.5 1.5 0 0 1 6 13.5m12 0a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5a1.5 1.5 0 0 1-1.5-1.5a1.5 1.5 0 0 1 1.5-1.5"
		/>
	</svg>
);
export const DeliveryIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 32 32"
	>
		<path fill={color} d="M4 16h12v2H4zm-2-5h10v2H2z" />
		<path
			fill={color}
			d="m29.919 16.606l-3-7A.999.999 0 0 0 26 9h-3V7a1 1 0 0 0-1-1H6v2h15v12.556A3.992 3.992 0 0 0 19.142 23h-6.284a4 4 0 1 0 0 2h6.284a3.98 3.98 0 0 0 7.716 0H29a1 1 0 0 0 1-1v-7a.997.997 0 0 0-.081-.394M9 26a2 2 0 1 1 2-2a2.002 2.002 0 0 1-2 2m14-15h2.34l2.144 5H23Zm0 15a2 2 0 1 1 2-2a2.002 2.002 0 0 1-2 2m5-3h-1.142A3.995 3.995 0 0 0 23 20v-2h5Z"
		/>
	</svg>
);
export const ClockIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16m1-8h4v2h-6V7h2z"
		/>
	</svg>
);

export const ClockIcon2 = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<g fill="none" stroke={color}>
			<circle cx="12" cy="12" r="8.5" />
			<path
				stroke-linecap="round"
				d="M5 2.804A6 6 0 0 0 2.804 5M19 2.804A6 6 0 0 1 21.196 5M12 6.5v5.25c0 .138.112.25.25.25h4.25"
			/>
		</g>
	</svg>
);

export const TableChairIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M12 22H6a2 2 0 0 1 2-2V8H2V5h14v3h-6v12a2 2 0 0 1 2 2M22 2v20h-2v-7h-5v7h-2v-8a2 2 0 0 1 2-2h5V2Z"
		/>
	</svg>
);
export const ProfileIcon = ({ size = 20, color = "#16283C" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<g
			fill="none"
			stroke={color}
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5"
		>
			<path d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10s10-4.477 10-10S17.523 2 12 2" />
			<path d="M4.271 18.346S6.5 15.5 12 15.5s7.73 2.846 7.73 2.846M12 12a3 3 0 1 0 0-6a3 3 0 0 0 0 6" />
		</g>
	</svg>
);

export const PaymentCardIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 20 20"
	>
		<path
			fill={color}
			d="M13.5 13a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zM2 6.75A2.75 2.75 0 0 1 4.75 4h10.5A2.75 2.75 0 0 1 18 6.75v6.5A2.75 2.75 0 0 1 15.25 16H4.75A2.75 2.75 0 0 1 2 13.25zM4.75 5A1.75 1.75 0 0 0 3 6.75V8h14V6.75A1.75 1.75 0 0 0 15.25 5zM17 9H3v4.25c0 .966.784 1.75 1.75 1.75h10.5A1.75 1.75 0 0 0 17 13.25z"
		/>
	</svg>
);
export const MoreIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<g
			fill="none"
			stroke={color}
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.5"
		>
			<path
				fill={color}
				d="M12 7.5a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1m0 10a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1m0-5a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1"
			/>
			<path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10" />
		</g>
	</svg>
);
export const MobileIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill="none"
			stroke={color}
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="1.45"
			d="M18 19V5a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9 6.001V6m3 .001V6m3 .001V6m-3 12.006V18"
		/>
	</svg>
);
export const QrCodeIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M3 11h2v2H3zm8-6h2v4h-2zm-2 6h4v4h-2v-2H9zm6 0h2v2h2v-2h2v2h-2v2h2v4h-2v2h-2v-2h-4v2h-2v-4h4v-2h2v-2h-2zm4 8v-4h-2v4zM15 3h6v6h-6zm2 2v2h2V5zM3 3h6v6H3zm2 2v2h2V5zM3 15h6v6H3zm2 2v2h2v-2z"
		/>
	</svg>
);

export const ImageIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 16 16"
	>
		<g fill={color}>
			<path d="M6.002 5.5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0" />
			<path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71l-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z" />
		</g>
	</svg>
);

export const CallIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="m9.367 3.313l.86 2.027c.375.884.167 1.923-.514 2.569L7.82 9.707c.117 1.075.478 2.134 1.084 3.177a8.678 8.678 0 0 0 2.271 2.594l2.276-.758c.862-.288 1.801.042 2.33.82l1.233 1.81c.614.904.504 2.15-.26 2.916l-.817.82c-.814.818-1.976 1.114-3.052.779c-2.539-.792-4.873-3.143-7.003-7.054c-2.133-3.916-2.885-7.238-2.258-9.967c.264-1.149 1.082-2.063 2.15-2.404l1.076-.344c1.008-.323 2.086.198 2.518 1.217m5.131 5.126l6.22-6.223a.75.75 0 0 1 1.134.976l-.072.084L15.56 9.5h4.69a.75.75 0 0 1 .744.648l.006.102a.75.75 0 0 1-.648.743L20.25 11h-6.555l-.098-.014l-.095-.026l-.068-.029a.721.721 0 0 1-.215-.15l-.041-.043l-.025-.032l-.048-.069l-.046-.091l-.032-.092l-.016-.07l-.01-.081l-.002-.053V3.745a.75.75 0 0 1 1.493-.102l.007.102zl6.22-6.223z"
		/>
	</svg>
);
export const ReportIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="m6 16.5l-3 2.94V11h3m5 3.66l-1.57-1.34L8 14.64V7h3m5 6l-3 3V3h3m2.81 9.81L17 11h5v5l-1.79-1.79L13 21.36l-3.47-3.02L5.75 22H3l6.47-6.34L13 18.64"
		/>
	</svg>
);
export const SmsIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 512 512"
	>
		<path
			fill={color}
			d="M256 448c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9c-5.5 9.2-11.1 16.6-15.2 21.6c-2.1 2.5-3.7 4.4-4.9 5.7c-.6.6-1 1.1-1.3 1.4l-.3.3c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c28.7 0 57.6-8.9 81.6-19.3c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9zM96 212.8c0-20.3 16.5-36.8 36.8-36.8H152c8.8 0 16 7.2 16 16s-7.2 16-16 16h-19.2c-2.7 0-4.8 2.2-4.8 4.8c0 1.6.8 3.1 2.2 4l29.4 19.6c10.3 6.8 16.4 18.3 16.4 30.7c0 20.3-16.5 36.8-36.8 36.8H112c-8.8 0-16-7.2-16-16s7.2-16 16-16h27.2c2.7 0 4.8-2.2 4.8-4.8c0-1.6-.8-3.1-2.2-4l-29.4-19.6c-10.2-6.8-16.4-18.3-16.4-30.7M372.8 176H392c8.8 0 16 7.2 16 16s-7.2 16-16 16h-19.2c-2.7 0-4.8 2.2-4.8 4.8c0 1.6.8 3.1 2.2 4l29.4 19.6c10.2 6.8 16.4 18.3 16.4 30.7c0 20.3-16.5 36.8-36.8 36.8H352c-8.8 0-16-7.2-16-16s7.2-16 16-16h27.2c2.7 0 4.8-2.2 4.8-4.8c0-1.6-.8-3.1-2.2-4l-29.4-19.6c-10.2-6.8-16.4-18.3-16.4-30.7c0-20.3 16.5-36.8 36.8-36.8m-152 6.4l35.2 46.9l35.2-46.9c4.1-5.5 11.3-7.8 17.9-5.6S320 185.1 320 192v96c0 8.8-7.2 16-16 16s-16-7.2-16-16v-48l-19.2 25.6c-3 4-7.8 6.4-12.8 6.4s-9.8-2.4-12.8-6.4L224 240v48c0 8.8-7.2 16-16 16s-16-7.2-16-16v-96c0-6.9 4.4-13 10.9-15.2s13.7.1 17.9 5.6"
		/>
	</svg>
);
export const PrivewCirculeIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<g fill="none" stroke={color} stroke-linecap="round" stroke-width="1.5">
			<path
				stroke-linejoin="round"
				d="m16.219 17.114l-.51 2.959a1.164 1.164 0 0 1-2.213.093l-2.788-7.882A1.164 1.164 0 0 1 12.2 10.79l8.036 2.788a1.164 1.164 0 0 1-.116 2.234l-3.112.51a1.165 1.165 0 0 0-.79.791"
			/>
			<path d="M21.502 9.314A9.726 9.726 0 1 0 9.297 21.5" />
		</g>
	</svg>
);
export const PriceManageIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M3 19V5h18v14zm1-1h16V6H4zm0 0V6zm4.5-1.5h1v-1h1.23q.328 0 .549-.221t.221-.548v-2.462q0-.327-.221-.548t-.548-.221H7.5v-2h4v-1h-2v-1h-1v1H7.27q-.328 0-.549.221T6.5 9.27v2.462q0 .327.221.548t.548.221H10.5v2h-4v1h2zm7.5-.52l1.538-1.538h-3.076zm-1.538-6.172h3.076L16 8.269z"
		/>
	</svg>
);
export const ManageIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 32 32"
	>
		<path
			fill={color}
			d="M28 17v5H4V6h10V4H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h8v4H8v2h16v-2h-4v-4h8a2 2 0 0 0 2-2v-5ZM18 28h-4v-4h4Z"
		/>
		<path
			fill={color}
			d="M30 10V8h-2.101a4.968 4.968 0 0 0-.732-1.753l1.49-1.49l-1.414-1.414l-1.49 1.49A4.968 4.968 0 0 0 24 4.101V2h-2v2.101a4.968 4.968 0 0 0-1.753.732l-1.49-1.49l-1.414 1.414l1.49 1.49A4.968 4.968 0 0 0 18.101 8H16v2h2.101a4.968 4.968 0 0 0 .732 1.753l-1.49 1.49l1.414 1.414l1.49-1.49a4.968 4.968 0 0 0 1.753.732V16h2v-2.101a4.968 4.968 0 0 0 1.753-.732l1.49 1.49l1.414-1.414l-1.49-1.49A4.968 4.968 0 0 0 27.899 10Zm-7 2a3 3 0 1 1 3-3a3.003 3.003 0 0 1-3 3"
		/>
	</svg>
);
export const AlertIcon = ({ size = 20, color = "#e4a111" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 1024 1024"
	>
		<path
			fill="#e4a111"
			d="M512 64a448 448 0 1 1 0 896a448 448 0 0 1 0-896m0 192a58.43 58.43 0 0 0-58.24 63.744l23.36 256.384a35.072 35.072 0 0 0 69.76 0l23.296-256.384A58.43 58.43 0 0 0 512 256m0 512a51.2 51.2 0 1 0 0-102.4a51.2 51.2 0 0 0 0 102.4"
		/>
	</svg>
);
export const AndroidIcon = ({ size = 20, color = "#e4a111" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 256 150"
	>
		<path
			fill="#34a853"
			d="M255.285 143.47c-.084-.524-.164-1.042-.251-1.56a128.119 128.119 0 0 0-12.794-38.288a128.778 128.778 0 0 0-23.45-31.86a129.166 129.166 0 0 0-22.713-18.005c.049-.08.09-.168.14-.25c2.582-4.461 5.172-8.917 7.755-13.38l7.576-13.068c1.818-3.126 3.632-6.26 5.438-9.386a11.776 11.776 0 0 0 .662-10.484a11.668 11.668 0 0 0-4.823-5.536a11.85 11.85 0 0 0-5.004-1.61a11.963 11.963 0 0 0-2.218.018a11.738 11.738 0 0 0-8.968 5.798c-1.814 3.127-3.628 6.26-5.438 9.386l-7.576 13.069c-2.583 4.462-5.173 8.918-7.755 13.38c-.282.487-.567.973-.848 1.467c-.392-.157-.78-.313-1.172-.462c-14.24-5.43-29.688-8.4-45.836-8.4c-.442 0-.879 0-1.324.006c-14.357.143-28.152 2.64-41.022 7.12a119.434 119.434 0 0 0-4.42 1.642c-.262-.455-.532-.911-.79-1.367c-2.583-4.462-5.173-8.918-7.755-13.38l-7.576-13.07a3915.27 3915.27 0 0 1-5.439-9.386A11.736 11.736 0 0 0 48.5.048a11.71 11.71 0 0 0-5.01 1.612a11.716 11.716 0 0 0-4.077 4.063c-.281.474-.532.967-.742 1.473a11.808 11.808 0 0 0-.365 8.188c.259.786.594 1.554 1.023 2.296a3973.32 3973.32 0 0 1 5.439 9.386c2.53 4.357 5.054 8.713 7.58 13.069c2.582 4.462 5.168 8.918 7.75 13.38c.02.038.046.075.065.112A129.184 129.184 0 0 0 45.32 64.38a129.693 129.693 0 0 0-22.2 24.015a127.737 127.737 0 0 0-9.34 15.24a128.238 128.238 0 0 0-10.843 28.764a130.743 130.743 0 0 0-1.951 9.524c-.087.518-.167 1.042-.247 1.56A124.978 124.978 0 0 0 0 149.118h256a130.44 130.44 0 0 0-.734-5.636z"
		/>
		<path
			fill="#202124"
			d="M194.59 113.712c5.122-3.41 5.867-11.3 1.661-17.62c-4.203-6.323-11.763-8.682-16.883-5.273c-5.122 3.41-5.868 11.3-1.662 17.621c4.203 6.322 11.764 8.682 16.883 5.272m-116.071-5.25c4.206-6.321 3.46-14.21-1.662-17.62c-5.123-3.41-12.68-1.05-16.886 5.27c-4.203 6.323-3.458 14.212 1.662 17.622c5.122 3.41 12.683 1.05 16.886-5.272"
		/>
	</svg>
);
export const AppleIcon = ({ size = 20, color = "#e4a111" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 32 32"
	>
		<path
			fill="#a8c2ab"
			d="M17.181 4.437A6 6 0 0 1 21.579 2a5.98 5.98 0 0 1-1.447 4.476a4.73 4.73 0 0 1-4.17 1.961a5.2 5.2 0 0 1 1.219-4m-.981 5.597c.946 0 2.7-1.3 4.989-1.3a6.25 6.25 0 0 1 5.484 2.8a6.08 6.08 0 0 0-3.028 5.3a6.24 6.24 0 0 0 3.772 5.7s-2.637 7.422-6.2 7.422c-1.636 0-2.908-1.1-4.631-1.1c-1.757 0-3.5 1.144-4.635 1.144c-3.251 0-7.364-7.041-7.364-12.7c0-5.568 3.478-8.489 6.74-8.489c2.121 0 3.766 1.223 4.873 1.223"
		/>
	</svg>
);

export const DashboardIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M11 2.05V13h10.95c-.501 5.053-4.765 9-9.95 9c-5.523 0-10-4.477-10-10c0-5.185 3.947-9.449 9-9.95m2 0A10.003 10.003 0 0 1 21.95 11H13z"
		/>
	</svg>
);

export const CategoryIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<g
			fill="none"
			stroke={color}
			stroke-linecap="round"
			stroke-linejoin="round"
		>
			<circle cx="17" cy="7" r="3" />
			<circle cx="7" cy="17" r="3" />
			<path d="M14 14h6v5a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zM4 4h6v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1z" />
		</g>
	</svg>
);
export const ProductIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<circle cx="5" cy="19" r="1" fill="currentColor" />
		<path fill={color} d="M4 4h2v9H4z" />
		<path
			fill={color}
			d="M7 2H3a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1m0 19H3V3h4Z"
		/>
		<circle cx="12" cy="19" r="1" fill={color} />
		<path fill={color} d="M11 4h2v9h-2z" />
		<path
			fill={color}
			d="M14 2h-4a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1m0 19h-4V3h4Z"
		/>
		<circle cx="19" cy="19" r="1" fill={color} />
		<path fill={color} d="M18 4h2v9h-2z" />
		<path
			fill={color}
			d="M21 2h-4a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1m0 19h-4V3h4Z"
		/>
	</svg>
);

export const OrderIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 512 512"
	>
		<path
			fill="none"
			stroke={color}
			stroke-linecap="round"
			stroke-linejoin="round"
			stroke-width="32"
			d="M80 176a16 16 0 0 0-16 16v216c0 30.24 25.76 56 56 56h272c30.24 0 56-24.51 56-54.75V192a16 16 0 0 0-16-16Zm80 0v-32a96 96 0 0 1 96-96h0a96 96 0 0 1 96 96v32"
		/>
	</svg>
);
export const NotificationIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M5 18.77v-1h1.616V9.845q0-1.96 1.24-3.447T11 4.546V3h2v1.546q1.904.366 3.144 1.853t1.24 3.447v7.923H19v1zm7 2.615q-.671 0-1.143-.473q-.472-.472-.472-1.143h3.23q0 .672-.472 1.144t-1.143.47M3.23 9.827q0-2.193.979-4.03q.977-1.838 2.622-3.04l.578.812Q5.967 4.631 5.1 6.258q-.868 1.626-.868 3.569zm16.54 0q0-1.943-.869-3.57q-.868-1.626-2.31-2.688l.578-.811q1.644 1.201 2.622 3.039q.978 1.837.978 4.03z"
		/>
	</svg>
);
export const BannerIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M6.616 21q-.691 0-1.153-.462T5 19.385V4.615q0-.69.463-1.152T6.616 3h10.769q.69 0 1.153.463T19 4.616v14.769q0 .69-.462 1.153T17.384 21zm0-1h10.769q.23 0 .423-.192t.192-.424V4.616q0-.231-.192-.424T17.384 4H6.616q-.231 0-.424.192T6 4.615v14.77q0 .23.192.423t.423.192m1.116-2.5h8.692l-2.757-3.654l-2.454 3.077l-1.589-1.884zM6 20V4z"
		/>
	</svg>
);
export const SettingIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="M19.9 12.66a1 1 0 0 1 0-1.32l1.28-1.44a1 1 0 0 0 .12-1.17l-2-3.46a1 1 0 0 0-1.07-.48l-1.88.38a1 1 0 0 1-1.15-.66l-.61-1.83a1 1 0 0 0-.95-.68h-4a1 1 0 0 0-1 .68l-.56 1.83a1 1 0 0 1-1.15.66L5 4.79a1 1 0 0 0-1 .48L2 8.73a1 1 0 0 0 .1 1.17l1.27 1.44a1 1 0 0 1 0 1.32L2.1 14.1a1 1 0 0 0-.1 1.17l2 3.46a1 1 0 0 0 1.07.48l1.88-.38a1 1 0 0 1 1.15.66l.61 1.83a1 1 0 0 0 1 .68h4a1 1 0 0 0 .95-.68l.61-1.83a1 1 0 0 1 1.15-.66l1.88.38a1 1 0 0 0 1.07-.48l2-3.46a1 1 0 0 0-.12-1.17ZM18.41 14l.8.9l-1.28 2.22l-1.18-.24a3 3 0 0 0-3.45 2L12.92 20h-2.56L10 18.86a3 3 0 0 0-3.45-2l-1.18.24l-1.3-2.21l.8-.9a3 3 0 0 0 0-4l-.8-.9l1.28-2.2l1.18.24a3 3 0 0 0 3.45-2L10.36 4h2.56l.38 1.14a3 3 0 0 0 3.45 2l1.18-.24l1.28 2.22l-.8.9a3 3 0 0 0 0 3.98m-6.77-6a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
		/>
	</svg>
);
export const ArchiveIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 24 24"
	>
		<path
			fill={color}
			d="m12 18l4-4l-1.4-1.4l-1.6 1.6V10h-2v4.2l-1.6-1.6L8 14zM5 8v11h14V8zm0 13q-.825 0-1.412-.587T3 19V6.525q0-.35.113-.675t.337-.6L4.7 3.725q.275-.35.687-.538T6.25 3h11.5q.45 0 .863.188t.687.537l1.25 1.525q.225.275.338.6t.112.675V19q0 .825-.587 1.413T19 21zm.4-15h13.2l-.85-1H6.25zm6.6 7.5"
		/>
	</svg>
);
export const PdfIcon = ({ size = 20, color = "currentColor" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={`${size}px`}
		height={`${size}px`}
		viewBox="0 0 58 58"
	>
		<path
			d="M50.95,12.187l-0.771-0.771L40.084,1.321L39.313,0.55C38.964,0.201,38.48,0,37.985,0H8.963C7.777,0,6.5,0.916,6.5,2.926V39
		v16.537V56c0,0.837,0.842,1.653,1.838,1.91c0.05,0.013,0.098,0.032,0.15,0.042C8.644,57.983,8.803,58,8.963,58h40.074
		c0.16,0,0.319-0.017,0.475-0.048c0.052-0.01,0.1-0.029,0.15-0.042C50.658,57.653,51.5,56.837,51.5,56v-0.463V39V13.978
		C51.5,13.211,51.408,12.645,50.95,12.187z M47.935,12H39.5V3.565L47.935,12z M8.963,56c-0.071,0-0.135-0.026-0.198-0.049
		C8.609,55.877,8.5,55.721,8.5,55.537V41h41v14.537c0,0.184-0.109,0.339-0.265,0.414C49.172,55.974,49.108,56,49.037,56H8.963z
		 M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595C37.525,2.126,37.5,2.256,37.5,2.391V14h11.609c0.135,0,0.264-0.025,0.39-0.058
		c0,0.015,0.001,0.021,0.001,0.036V39H8.5z"
		/>
		<path
			d="M22.042,44.744c-0.333-0.273-0.709-0.479-1.128-0.615c-0.419-0.137-0.843-0.205-1.271-0.205h-2.898V54h1.641v-3.637h1.217
		c0.528,0,1.012-0.077,1.449-0.232s0.811-0.374,1.121-0.656c0.31-0.282,0.551-0.631,0.725-1.046c0.173-0.415,0.26-0.877,0.26-1.388
		c0-0.483-0.103-0.918-0.308-1.306S22.375,45.018,22.042,44.744z M21.42,48.073c-0.101,0.278-0.232,0.494-0.396,0.649
		c-0.164,0.155-0.344,0.267-0.54,0.335c-0.196,0.068-0.395,0.103-0.595,0.103h-1.504v-3.992h1.23c0.419,0,0.756,0.066,1.012,0.198
		c0.255,0.132,0.453,0.296,0.595,0.492c0.141,0.196,0.234,0.401,0.28,0.615c0.045,0.214,0.068,0.403,0.068,0.567
		C21.57,47.451,21.52,47.795,21.42,48.073z"
		/>
		<path
			d="M31.954,45.4c-0.424-0.446-0.957-0.805-1.6-1.073s-1.388-0.403-2.235-0.403h-3.035V54h3.814
		c0.127,0,0.323-0.016,0.588-0.048c0.264-0.032,0.556-0.104,0.875-0.219c0.319-0.114,0.649-0.285,0.991-0.513
		s0.649-0.54,0.923-0.937s0.499-0.889,0.677-1.477s0.267-1.297,0.267-2.126c0-0.602-0.105-1.188-0.314-1.757
		C32.694,46.355,32.378,45.847,31.954,45.4z M30.758,51.73c-0.492,0.711-1.294,1.066-2.406,1.066h-1.627v-7.629h0.957
		c0.784,0,1.422,0.103,1.914,0.308s0.882,0.474,1.169,0.807s0.48,0.704,0.581,1.114c0.1,0.41,0.15,0.825,0.15,1.244
		C31.496,49.989,31.25,51.02,30.758,51.73z"
		/>
		<polygon
			points="35.598,54 37.266,54 37.266,49.461 41.477,49.461 41.477,48.34 37.266,48.34 37.266,45.168 41.9,45.168 
		41.9,43.924 35.598,43.924 	"
		/>
		<path
			d="M38.428,22.961c-0.919,0-2.047,0.12-3.358,0.358c-1.83-1.942-3.74-4.778-5.088-7.562c1.337-5.629,0.668-6.426,0.373-6.802
		c-0.314-0.4-0.757-1.049-1.261-1.049c-0.211,0-0.787,0.096-1.016,0.172c-0.576,0.192-0.886,0.636-1.134,1.215
		c-0.707,1.653,0.263,4.471,1.261,6.643c-0.853,3.393-2.284,7.454-3.788,10.75c-3.79,1.736-5.803,3.441-5.985,5.068
		c-0.066,0.592,0.074,1.461,1.115,2.242c0.285,0.213,0.619,0.326,0.967,0.326h0c0.875,0,1.759-0.67,2.782-2.107
		c0.746-1.048,1.547-2.477,2.383-4.251c2.678-1.171,5.991-2.229,8.828-2.822c1.58,1.517,2.995,2.285,4.211,2.285
		c0.896,0,1.664-0.412,2.22-1.191c0.579-0.811,0.711-1.537,0.39-2.16C40.943,23.327,39.994,22.961,38.428,22.961z M20.536,32.634
		c-0.468-0.359-0.441-0.601-0.431-0.692c0.062-0.556,0.933-1.543,3.07-2.744C21.555,32.19,20.685,32.587,20.536,32.634z
		 M28.736,9.712c0.043-0.014,1.045,1.101,0.096,3.216C27.406,11.469,28.638,9.745,28.736,9.712z M26.669,25.738
		c1.015-2.419,1.959-5.09,2.674-7.564c1.123,2.018,2.472,3.976,3.822,5.544C31.031,24.219,28.759,24.926,26.669,25.738z
		 M39.57,25.259C39.262,25.69,38.594,25.7,38.36,25.7c-0.533,0-0.732-0.317-1.547-0.944c0.672-0.086,1.306-0.108,1.811-0.108
		c0.889,0,1.052,0.131,1.175,0.197C39.777,24.916,39.719,25.05,39.57,25.259z"
		/>
	</svg>
);
