import React from "react";
import "./styles.css";
import { ModalContainer } from "./Styled";
import { AlertIcon } from "../DesignSystem/Icons/Icons";
import { Typography } from "../DesignSystem/Typography";
import { useDispatch } from "react-redux";
import { clear } from "../../redux/tostSlice";

const ErrorModal = ({ getTostInfo }) => {
  const dispatch = useDispatch();

  const closeModal = () => {
    if (getTostInfo?.message == "Unauthenticated.") window.location.reload();
    dispatch(clear());
  };
  return (
    <div
      className="position-fixed w-100 h-100 text-center loading"
      style={{
        background: "#0000003d",
        color: "white",
        top: 0,
        left: 0,
        zIndex: 9999,
      }}
    >
      <ModalContainer>
        <div class="py-3">
          <AlertIcon size={45} />
        </div>
        <h6>Sorry!</h6>
        <div>{getTostInfo?.message}</div>
        <div className="px-2 py-3 fs-5 cp">
          <button className="button" onClick={closeModal}>
            Close
          </button>
        </div>
      </ModalContainer>
    </div>
  );
};

export default ErrorModal;
