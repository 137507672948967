import { DashboardIcon } from "../components/DesignSystem/Icons/Icons";

export const superAdminMenuItems = [
  {
    index: 1,
    label: "Quotation Mng",
    url: "/",
    icon: <DashboardIcon />,
  },
];
