import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import "./styles.css";

const alertIcon = {
  error: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      height="1.5em"
      viewBox="0 0 36 36"
    >
      <path
        fill="currentColor"
        d="M18 6a12 12 0 1 0 12 12A12 12 0 0 0 18 6m-1.49 6a1.49 1.49 0 0 1 3 0v6.89a1.49 1.49 0 1 1-3 0ZM18 25.5a1.72 1.72 0 1 1 1.72-1.72A1.72 1.72 0 0 1 18 25.5"
        className="clr-i-solid clr-i-solid-path-1"
      />
      <path fill="none" d="M0 0h36v36H0z" />
    </svg>
  ),
  success: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="M10 20a10 10 0 0 1 0-20a10 10 0 1 1 0 20m-2-5l9-8.5L15.5 5L8 12L4.5 8.5L3 10z"
      />
    </svg>
  ),
  warning: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 512 512"
    >
      <path
        fill="#ffb636"
        d="M12.51 470.379L234.371 16.008c6.439-13.187 25.17-13.363 31.855-.299l232.51 454.371c6.064 11.849-2.542 25.92-15.853 25.92H28.512c-13.164 0-21.778-13.791-16.002-25.621"
      />
      <path
        fill="#2b3b47"
        d="M284.332 173L272.15 336.498c-.911 12.233-11.567 21.411-23.8 20.499c-11.116-.828-19.706-9.707-20.499-20.499L215.668 173c-1.413-18.961 12.813-35.478 31.774-36.89s35.478 12.813 36.89 31.774c.124 1.662.109 3.5 0 5.116M250 391.873c-17.432 0-31.564 14.131-31.564 31.564C218.436 440.869 232.568 455 250 455s31.564-14.131 31.564-31.564c0-17.432-14.132-31.563-31.564-31.563"
      />
    </svg>
  ),
};
const alertColor = {
  error: "#741C06",
  success: "#ECC113",
  warning: "#F9EAAD",
};

export const Alert = forwardRef((props, ref) => {
  const [response, setResponse] = React.useState("");
  const [open, setOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    handleClick(getTostInfo) {
      setResponse(getTostInfo);
      setOpen(!open);
    },
  }));
  useEffect(() => {
    setTimeout(function () {
      setResponse("");
      setOpen(false);
    }, 3000);
  }, [open]);
  return (
    <div
      className="toast-container position-fixed bottom-0 end-0 p-3 "
      data-bs-autohide={true}
    >
      <div
        id="liveToast"
        className={`d-flex toast shadow fade ${open ? "show" : "hidden"}`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        style={{ backgroundColor: alertColor[response?.type] }}
      >
        <div className="p-2">{alertIcon[response?.type]}</div>
        <div className="toast-body">{response?.message}</div>
      </div>
    </div>
  );
});
