import React, { useEffect, useRef, useState } from "react";
import { Typography } from "../../components/DesignSystem/Typography";
import { OutlineButton } from "../../components/DesignSystem/Buttons";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useMyNavigation } from "../../hooks";
import { InputField } from "../../components/DesignSystem/FormField/InputField";
import { TextAreaField } from "../../components/DesignSystem/FormField/TextAreaField";
import AddQuotationDetails from "./AddQuotationDetails";
import { QuotationItems } from "./QuotationItems";
import {
  clear,
  fetchGenricQoteItem,
  updateQuoteItemSlice,
  fetchCalculation,
} from "../../redux/quotationSlice";
import { addQuatation, getQuotationDetails } from "../../action";
import { useLocation } from "react-router-dom";
import { getQuotationDet } from "../../redux/quotationMngSlice";
import { SelectField } from "../../components/DesignSystem/FormField";
import {
  ClientOptionsForMobileType,
  NeighbourOptionsForMobileType,
} from "../../Constant";
import { filterObjectByValue } from "../../util";
const resData = {
  info: {
    client_name: "",
    client_email: "",
    client_suburb: "",
    client_state: "",
    client_postcode: "",
    client_phone_no: "",
    client_address: "",
    neighbour_name: "",
    neighbour_email: "",
    neighbour_suburb: "",
    neighbour_state: "",
    neighbour_postcode: "",
    neighbour_phone_no: "",
    neighbour_address: "",
  },
};
const AddQuotation = () => {
  const genricQoteItem = useSelector(fetchGenricQoteItem);
  const getQuotationinfo = useSelector(getQuotationDet);
  const getCalculation = useSelector(fetchCalculation);

  const { state } = useLocation();
  const [isEdit, setIsEdit] = useState(false);

  const addQuotationDetailsModal = useRef();
  const dispatch = useDispatch();
  const { push } = useMyNavigation();
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      ...resData,
    },
  });
  useEffect(() => {
    dispatch(clear());
    if (state?.id) {
      setIsEdit(true);
      dispatch(getQuotationDetails(state?.id));
    }
  }, [dispatch, state?.id]);

  useEffect(() => {
    if (getQuotationinfo && state?.id) {
      setValue("info", getQuotationinfo);
      setValue(
        "info.client_phone_type",
        filterObjectByValue(
          ClientOptionsForMobileType,
          getQuotationinfo?.client_phone_type
        )
      );
      setValue(
        "info.neighbour_phone_type",
        filterObjectByValue(
          NeighbourOptionsForMobileType,
          getQuotationinfo?.neighbour_phone_type
        )
      );
    }
  }, [genricQoteItem, getQuotationinfo, setValue, state?.id]);
  useEffect(() => {
    if (getQuotationinfo?.quotation_detrails && state?.id) {
      dispatch(updateQuoteItemSlice(getQuotationinfo?.quotation_detrails));
    }
  }, [dispatch, getQuotationinfo?.quotation_detrails, state?.id]);

  const addQuotationDetails = (item) => {
    addQuotationDetailsModal.current.handleAddQuotationDetails(item);
  };
  const onSubmit = ({ info }) => {
    if (Boolean(genricQoteItem?.length)) {
      const item = {
        ...info,
        client_phone_type: info?.client_phone_type?.label,
        neighbour_phone_type: info?.neighbour_phone_type?.label,
        client_share: getCalculation?.client_share,
        neighbour_share: getCalculation?.neighbours_share,
        items: genricQoteItem,
        quotation_detrails: [],
      };
      dispatch(addQuatation(item));
      reset(resData);
      push("/");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Typography align="text-start">{`${
          isEdit ? "Update" : "Create"
        } Quotation`}</Typography>
        <OutlineButton label="Back" onClick={() => push("/")} />
      </div>
      <div className="container p-2">
        <div className="p-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset class="border p-3 mb-4">
              <div className="row ">
                <div class=" col-md-6">
                  <div class=" col-md-12 ">
                    <legend>Client Information</legend>
                  </div>
                  <div class=" col-md-12 ">
                    <label for="username" class="form-label azm-color-666">
                      Name:
                    </label>
                    <Controller
                      name="info.client_name"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, name, value, ref } }) => (
                        <InputField
                          name={name}
                          value={value}
                          errors={errors}
                          errorMessage="Client name"
                          type="text"
                          onChange={onChange}
                          ref={ref}
                        />
                      )}
                    />
                  </div>
                  <div class=" col-md-12 ">
                    <label for="username" class="form-label azm-color-666">
                      Address:
                    </label>
                    <Controller
                      name="info.client_address"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, name, value, ref } }) => (
                        <InputField
                          name={name}
                          value={value}
                          errors={errors}
                          errorMessage="Client Address"
                          type="text"
                          onChange={onChange}
                          ref={ref}
                        />
                      )}
                    />
                  </div>
                  <div class=" col-md-12 ">
                    <label for="username" class="form-label azm-color-666">
                      Suburb
                    </label>
                    <Controller
                      name="info.client_suburb"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, name, value, ref } }) => (
                        <InputField
                          name={name}
                          value={value}
                          errors={errors}
                          errorMessage="Client Suburb"
                          type="text"
                          onChange={onChange}
                          ref={ref}
                        />
                      )}
                    />
                  </div>
                  <div class=" col-md-12 ">
                    <label for="username" class="form-label azm-color-666">
                      State
                    </label>
                    <Controller
                      name="info.client_state"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, name, value, ref } }) => (
                        <InputField
                          name={name}
                          value={value}
                          errors={errors}
                          errorMessage="Client State"
                          type="text"
                          onChange={onChange}
                          ref={ref}
                        />
                      )}
                    />
                  </div>
                  <div class=" col-md-12 ">
                    <label for="username" class="form-label azm-color-666">
                      Postcode
                    </label>
                    <Controller
                      name="info.client_postcode"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, name, value, ref } }) => (
                        <InputField
                          name={name}
                          value={value}
                          errors={errors}
                          errorMessage="Client Postcode"
                          type="text"
                          onChange={onChange}
                          ref={ref}
                        />
                      )}
                    />
                  </div>
                  <div class="col-md-12">
                    {/* <label for="username" class="form-label azm-color-666">
                      Phone No
                    </label> */}
                    <div className="row ">
                      <div class=" col-md-6">
                        <Controller
                          name="info.client_phone_type"
                          control={control}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <SelectField
                              name={name}
                              value={value}
                              optionsItems={ClientOptionsForMobileType}
                              errors={errors}
                              placeholder="Client Phone"
                              errorMessage="Please select phone type."
                              ref={ref}
                              onChange={(e) => {
                                onChange(e);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div class=" col-md-6">
                        <Controller
                          name="info.client_phone_no"
                          control={control}
                          rules={{ required: true, pattern: /^\d{10}$/ }}
                          render={({
                            field: { onChange, name, value, ref },
                          }) => (
                            <InputField
                              name={name}
                              value={value}
                              errors={errors}
                              errorMessage="Client phone no"
                              type="text"
                              onChange={onChange}
                              ref={ref}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div class=" col-md-12 ">
                    <label for="username" class="form-label azm-color-666">
                      Email
                    </label>
                    <Controller
                      name="info.client_email"
                      control={control}
                      rules={{
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      }}
                      render={({ field: { onChange, name, value, ref } }) => (
                        <InputField
                          name={name}
                          value={value}
                          errors={errors}
                          errorMessage="Client email"
                          type="text"
                          onChange={onChange}
                          ref={ref}
                        />
                      )}
                    />
                  </div>
                  {/* 
                  <div class=" col-md-12 ">
                    <label for="username" class="form-label azm-color-666">
                      Address:
                    </label>
                    <Controller
                      name="info.client_address"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, name, value, ref } }) => (
                        <TextAreaField
                          name={name}
                          value={value}
                          errors={errors}
                          errorMessage="Client address"
                          type="text"
                          onChange={onChange}
                          ref={ref}
                        />
                      )}
                    />
                  </div> */}
                </div>

                <div class=" col-md-6">
                  <div className="row">
                    <div class=" col-md-12">
                      <legend>Neighbour Information</legend>
                    </div>
                    <div class=" col-md-12 ">
                      <label for="username" class="form-label azm-color-666">
                        Name:
                      </label>
                      <Controller
                        name="info.neighbour_name"
                        control={control}
                        render={({ field: { onChange, name, value, ref } }) => (
                          <InputField
                            name={name}
                            value={value}
                            errors={errors}
                            errorMessage="Neighbour name"
                            type="text"
                            onChange={onChange}
                            ref={ref}
                          />
                        )}
                      />
                    </div>
                    <div class=" col-md-12 ">
                      <label for="username" class="form-label azm-color-666">
                        Address
                      </label>
                      <Controller
                        name="info.neighbour_address"
                        control={control}
                        render={({ field: { onChange, name, value, ref } }) => (
                          <InputField
                            name={name}
                            value={value}
                            errors={errors}
                            errorMessage="Neighbour Address"
                            type="text"
                            onChange={onChange}
                            ref={ref}
                          />
                        )}
                      />
                    </div>
                    <div class=" col-md-12 ">
                      <label for="username" class="form-label azm-color-666">
                        Suburb
                      </label>
                      <Controller
                        name="info.neighbour_suburb"
                        control={control}
                        render={({ field: { onChange, name, value, ref } }) => (
                          <InputField
                            name={name}
                            value={value}
                            errors={errors}
                            errorMessage="Neighbour suburb"
                            type="text"
                            onChange={onChange}
                            ref={ref}
                          />
                        )}
                      />
                    </div>
                    <div class=" col-md-12 ">
                      <label for="username" class="form-label azm-color-666">
                        State
                      </label>
                      <Controller
                        name="info.neighbour_state"
                        control={control}
                        render={({ field: { onChange, name, value, ref } }) => (
                          <InputField
                            name={name}
                            value={value}
                            errors={errors}
                            errorMessage="Neighbour state"
                            type="text"
                            onChange={onChange}
                            ref={ref}
                          />
                        )}
                      />
                    </div>
                    <div class=" col-md-12 ">
                      <label for="username" class="form-label azm-color-666">
                        Postcode
                      </label>
                      <Controller
                        name="info.neighbour_postcode"
                        control={control}
                        // rules={{ pattern: /^\d+$/ }}
                        render={({ field: { onChange, name, value, ref } }) => (
                          <InputField
                            name={name}
                            value={value}
                            errors={errors}
                            errorMessage="Neighbour postcode"
                            type="text"
                            onChange={onChange}
                            ref={ref}
                          />
                        )}
                      />
                    </div>
                    <div class=" col-md-12 ">
                      {/* <label for="username" class="form-label azm-color-666">
                        Phone No
                      </label> */}
                      <div className="row ">
                        <div class=" col-md-6">
                          <Controller
                            name="info.neighbour_phone_type"
                            control={control}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => (
                              <SelectField
                                name={name}
                                value={value}
                                optionsItems={NeighbourOptionsForMobileType}
                                errors={errors}
                                placeholder="Neighbour Phone"
                                errorMessage="Please select phone type."
                                ref={ref}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            )}
                          />
                        </div>
                        <div class=" col-md-6">
                          <Controller
                            name="info.neighbour_phone_no"
                            control={control}
                            rules={{ pattern: /^\d{10}$/ }}
                            render={({
                              field: { onChange, name, value, ref },
                            }) => (
                              <InputField
                                name={name}
                                value={value}
                                errors={errors}
                                errorMessage="Neighbour phone no"
                                type="text"
                                onChange={onChange}
                                ref={ref}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div class=" col-md-12 ">
                      <label for="username" class="form-label azm-color-666">
                        Email
                      </label>
                      <Controller
                        name="info.neighbour_email"
                        control={control}
                        rules={{
                          pattern:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        }}
                        render={({ field: { onChange, name, value, ref } }) => (
                          <InputField
                            name={name}
                            value={value}
                            errors={errors}
                            errorMessage="Neighbour email"
                            type="text"
                            onChange={onChange}
                            ref={ref}
                          />
                        )}
                      />
                    </div>

                    {/* <div class=" col-md-12 ">
                      <label for="username" class="form-label azm-color-666">
                        Address:
                      </label>
                      <Controller
                        name="info.neighbour_address"
                        control={control}
                        render={({ field: { onChange, name, value, ref } }) => (
                          <TextAreaField
                            name={name}
                            value={value}
                            errors={errors}
                            errorMessage="Neighbour address"
                            type="text"
                            onChange={onChange}
                            ref={ref}
                          />
                        )}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset class="border p-3 mb-4">
              <div className="d-flex justify-content-between mb-3">
                <h4>Quatation Details</h4>
                <div class="">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() => addQuotationDetails()}
                  >
                    Add Quote Item
                  </button>
                </div>
              </div>

              <QuotationItems addQuotationDetails={addQuotationDetails} />
            </fieldset>
            <div className="d-flex justify-content-end mt-3">
              <OutlineButton
                label={`${isEdit ? "Upate" : "Create"} Quotation`}
              />
            </div>
          </form>{" "}
          <AddQuotationDetails ref={addQuotationDetailsModal} />
        </div>
      </div>
    </>
  );
};

export default AddQuotation;
