import { createGlobalStyle, styled } from "styled-components";

export const GlobalStyle = createGlobalStyle`
body {
    background: aliceblue;
    font-family: 'Roboto', sans-serif;
}
`;
export const Wrapper = styled.div`
  background-image: url("../../banner.jpg");
  object-fit: cover;
  height: 20%;
  background-size: cover !important;
  background-position: 0 0 !important;
  position: relative;
  z-index: 1;
`;
export const Container = styled.div`
  height: 40rem;
  .login-box {
    border-radius: 5%;
    margin-top: 6rem;
    height: auto;
    background: #ffffff;
    text-align: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .login-key {
    font-size: 80px;
    line-height: 100px;
    background: -webkit-linear-gradient(#27ef9f, #0db8de);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .login-title {
    margin-top: 15px;
    text-align: center;
    font-size: 20px;
    letter-spacing: 2px;
    margin-top: 15px;
    font-weight: bold;
    color: #94989d;
  }
  .company-title {
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    color: #000204;
  }
  .login-form {
    margin-top: 25px;
    text-align: left;
  }

  .form-group {
    margin-bottom: 20px;
    outline: 0px;
  }

  input:focus {
    outline: none;
    box-shadow: 0 0 0;
  }

  .btn-outline-primary {
    border-color: #731d06;
    color: #731d06;
    border-radius: 0px;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }

  .btn-outline-primary:hover {
    background-color: #731d06;
    right: 0px;
    color: #ffff;
    // font-size: 17px;
  }

  .login-btm {
    float: left;
  }

  .login-button {
    padding-right: 0px;
    text-align: right;
    margin-bottom: 25px;
  }

  .login-text {
    text-align: left;
    padding-left: 0px;
    color: #a2a4a4;
  }

  .loginbttm {
    padding: 0px;
  }
  .form-horizontal .btn:hover,
  .form-horizontal .btn:focus {
    color: #fff;
    letter-spacing: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
`;
export const LogoContainer = styled.span``;
