import { forwardRef } from "react";
import "./styles.css";
import { isObjectEmpty } from "../../../util";

const getErrorField = (errors, name) => {
  const fieldName = name.split(".");
  return !isObjectEmpty(errors)
    ? fieldName?.length === 2
      ? errors[fieldName[0]][fieldName[1]]
      : errors[name]
    : "";
};
export const InputField = forwardRef(
  (
    {
      name,
      value,
      errors = "",
      placeholder,
      isDisabled = false,
      isReadonly = false,
      errorMessage,
      type,
      styles,
      ...rest
    },
    ref
  ) => {
    return (
      <>
        <input
          type={type}
          value={value}
          readOnly={isReadonly}
          disabled={isDisabled}
          label={placeholder}
          placeholder={placeholder}
          className={`form-control ${styles} ${
            getErrorField(errors, name) ? "field-border" : ""
          }`}
          id={name}
          ref={ref}
          {...rest}
        />
        {errors && <label className="error-msg">{errors[name]?.message}</label>}
      </>
    );
  }
);
