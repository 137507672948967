export const getPageUrl = (url) => {
  const urlSegment = url?.hash.split("/");
  return url?.hash.split("/")[urlSegment?.length - 1];
};

export const encode = (item) => {
  return btoa(btoa(String(item)));
};

export const decode = (item) => {
  return atob(atob(String(item)));
};

export const logout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("token");
  localStorage.removeItem("userLocation");
  window.location.reload();
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] !== value);
};

export const convertarrayFlat = (items) => {
  return items.filter(Boolean).flat();
};

export const getMinValue = (item, fieldValue) =>
  item.reduce((pre, curr) =>
    pre[fieldValue] < curr[fieldValue] ? pre[fieldValue] : curr[fieldValue]
  );

export const splitName = (name) => {
  const values = name?.split(" ");
  const first_name = values[0];
  const last_name = values[1] ? name?.substr(name?.indexOf(" ") + 1) : "";
  return { first_name, last_name };
};

export const getUserLocation = () =>
  JSON.parse(localStorage.getItem("userLocation")) || {
    label: "",
    value: "",
    pincode: "Location",
  };

export const isObjectEmpty = (objectName) => {
  return JSON.stringify(objectName) === "{}";
};
export const filterObjectByValue = (object = [], value) => {
  return object.find((item) => item?.value === value);
};

export const getKaysFromObject = (items) => {
  return Object.keys(
    Object.keys(items).reduce((prev, key) => {
      items[key] === true && (prev[key] = items[key]);
      return prev;
    }, {})
  );
};
